import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import{SignUp} from './interfaces/sign-up.interface';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn:'root'
})
export class SignUpService{

  public slideNumber=4;
  public slideCount=1;
  public slideName='slide-1';
  public signUpForm:any;
  public signUpObj:SignUp={
    companyName:'',
    fullName:'',
    phoneNumber:'',
    email:'',
    agreeToTerms:false,
    forms:'',
    //agreeToAutoPay:false,
    //stripeToken:''
  };
  public monthlyPrice = 199;
  public formsList:string = '';
  public formCount:number = 0;
  constructor(private http:HttpClient){
  }

  public signUpNewCompany(obj:SignUp){
    let f = JSON.stringify(obj);
    return this.http.post('https://lawformspro.com/service/api/signup/newcompany', f, httpOptions);
  }

  public getNextSlide(){
    if(this.slideCount < this.slideNumber){
      this.slideCount++;
      this.slideName='slide-'+this.slideCount;
    }
  }

  public getPreviousSlide(){
    if(this.slideCount > 1){
      this.slideCount--;
      this.slideName='slide-'+this.slideCount;
    }
  }
}
