import { TestHeaderComponent } from "./testHeader.component";
import {DemoHeaderComponent} from './demo-header/demo-header.component';
import { AthenaTestHeaderComponent } from "./athena-test-header/athena-test-header.component";
import { LawOfficeOfMLTHeaderComponent } from "./law-office-of-mltheader/law-office-of-mltheader.component";
import { BerkelyDisabilityHeaderComponent } from "./berkely-disability-header/berkely-disability-header.component";
import { AmericanDisabilityLawComponent } from "./american-disability-law/american-disability-law.component";
import { GonzalezGoetzDisabilityHeaderComponent } from "./gonzalez-goetz-disability-header/gonzalez-goetz-disability-header.component";
import { GonzalezGoetzMedicareHeaderComponent } from "./gonzalez-goetz-medicare-header/gonzalez-goetz-medicare-header.component";
import { AshcraftLawHeaderComponent } from "./ashcraft-law-header/ashcraft-law-header.component";
import { BettyLgJonesHeaderComponent } from "./betty-lg-jones-header/betty-lg-jones-header.component";
import { TvcDischargeUpgradeHeaderComponent } from "./tvc-discharge-upgrade-header/tvc-discharge-upgrade-header.component";
import { McmanesLawComponent } from "./mcmanes-law/mcmanes-law.component";
import { StewartDisabilityLawHeaderComponent } from "./stewart-disability-law-header/stewart-disability-law-header.component";

export const headerComponentMap ={
  'TestHeaderComponent':TestHeaderComponent,
  'DemoHeaderComponent':DemoHeaderComponent,
  'AthenaTestHeaderComponent':AthenaTestHeaderComponent,
  'BerkelyDisabilityHeaderComponent':BerkelyDisabilityHeaderComponent,
  'AmericanDisabilityLawComponent':AmericanDisabilityLawComponent,
  'GonzalezGoetzDisabilityHeaderComponent':GonzalezGoetzDisabilityHeaderComponent,
  'GonzalezGoetzMedicareHeaderComponent':GonzalezGoetzMedicareHeaderComponent,
  'AshcraftLawHeaderComponent':AshcraftLawHeaderComponent,
  'LawOfficeOfMLTHeaderComponent':LawOfficeOfMLTHeaderComponent,
  'BettyLgJonesHeaderComponent' : BettyLgJonesHeaderComponent,
  'TvcDischargeUpgradeHeaderComponent': TvcDischargeUpgradeHeaderComponent,
  'McmanesLawComponent':McmanesLawComponent,
  'StewartDisabilityLawHeaderComponent': StewartDisabilityLawHeaderComponent
};
