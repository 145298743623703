import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentSuccessComponent } from './payment-success.component';
import { SharedModule } from 'src/app/shared';
import { FootersModule } from 'src/app/blocks/footers/footers.module';
import { ActionsModule } from 'src/app/blocks/actions/actions.module';
import { ShellModule } from 'src/app/shell/shell.module';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [PaymentSuccessComponent],
  imports: [
    CommonModule,
    SharedModule,
    FootersModule,
    ActionsModule,
    ShellModule,
    MatCardModule,
  ],
  exports:[PaymentSuccessComponent]
})
export class PaymentSuccessModule { }
