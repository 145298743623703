import{Injectable} from  '@angular/core';
import{Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from './auth.service';
import { catchError, map } from 'rxjs/operators';
@Injectable()
export class AuthGuardService implements CanActivate{
  constructor(public auth:AuthService, public router:Router){

  }

  canActivate(route: ActivatedRouteSnapshot):Observable<boolean>{
     return this.auth.CheckCompanyAuth(route.params['lawName']).pipe(map((response:{authenticated:boolean}) => {

      if(response){
         return true;
       }

       this.router.navigate(['nocompany']);
       return false;
     }));

  }
}
