import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import{ StripePaymentComponent} from  './stripe-payment.component';
import {MatCardModule} from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FormControlsModule } from '../../common/form-controls/formControls.module';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule as fModule, ReactiveFormsModule } from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SignUpModule } from '../sign-up/sign-up.module';
import { PricingTotalComponent } from '../sign-up/pricing-total/pricing-total.component';
@NgModule({
  declarations: [StripePaymentComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatInputModule,
    MatGridListModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatNativeDateModule,
    FormControlsModule,
    MatCheckboxModule,
    fModule,
    MatButtonModule,
    CreditCardDirectivesModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
  ],
  exports:[StripePaymentComponent],
  entryComponents:[StripePaymentComponent],
})
export class StripePaymentModule { }
