import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormRegisterSimpleInlineComponent } from './form-register-simple-inline/form-register-simple-inline.component';
import { FormContactComponent } from './form-contact/form-contact.component';
import { FormRegisterCompanyComponent } from './form-register-company/form-register-company.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
@NgModule({
  declarations: [
    FormRegisterSimpleInlineComponent,
    FormContactComponent,
    FormRegisterCompanyComponent,
  ],
  imports: [CommonModule, FormsModule,ReactiveFormsModule,MatFormFieldModule,MatInputModule,MatProgressSpinnerModule],
  exports: [
    FormRegisterSimpleInlineComponent,
    FormContactComponent,
    FormRegisterCompanyComponent,
  ],
})
export class ActionsModule {}
