<dc-navbar-default [darkLinks]="false"></dc-navbar-default>
<dc-page-header-wave
  [title]="'Contact Us'"
  [subtitle]="
    'Get in touch and let us know how we can help. Fill out the form and we’ll be in touch as soon as possible.'
  "
  [containerClass]="'pb-9'"
>
</dc-page-header-wave>

<dc-contact-form></dc-contact-form>
<!-- <dc-contact-other-channels></dc-contact-other-channels> -->

<dc-footer2></dc-footer2>
