import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { FootersModule } from '../../blocks/footers/footers.module';
import { ActionsModule } from '../../blocks/actions/actions.module';

//import { ContactRoutingModule } from './contact-routing.module';
import { ContactComponent } from './components/contact/contact.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { ContactOtherChannelsComponent } from './components/contact-other-channels/contact-other-channels.component';
import { ShellModule } from 'src/app/shell/shell.module';

@NgModule({
  declarations: [
    ContactComponent,
    ContactFormComponent,
    ContactOtherChannelsComponent,
  ],
  imports: [SharedModule, FootersModule, ActionsModule, ShellModule],
  exports: [ContactComponent],
})
export class ContactModule {}
