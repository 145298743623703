import { Injectable } from '@angular/core';
import { FormTypes } from 'src/app/common/enums/formTypes.enum';
import { PersonalInjuryForm } from '../interfaces/personal-injury-form/PersonalInjuryForm';
import { SocialSecurityForm } from '../interfaces/social-security-form/SocialSecurityForm';
import { VeteranAffairsForm } from '../interfaces/veteran-affairs-form/VeteranAffairsForm';
import { MedicareForm } from '../interfaces/medicare-form/MedicareForm';
import { WorkersCompForm } from '../interfaces/workers-comp-form/WorkersCompForm';
import { FormBuilder, FormControl, FormGroup, NgControlStatus, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomForm } from '../interfaces/custom-form/custom-form';
import { CustomSection } from '../interfaces/custom-form/custom-section';
import { CustomControl } from '../interfaces/custom-form/custom-control';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const sendFormsHttpOptions = {
  headers: new HttpHeaders({ 'Accept': 'text/plain', 'Content-Type': 'application/json' }),
  responseType: 'text' as 'json'
}

const sendFormsUploadHttpOptions = {
  headers: new HttpHeaders({ 'Accept': 'text/plain', 'Content-Type': 'application/x-www-form-urlencoded' }),
  responseType: 'text' as 'json'
}
@Injectable({
  providedIn: 'root'
})

export class FormService {
  public groupControlValues: any = {};
  public groupValues: Map<string, string> = new Map<string, string>();
  public groupValuesClearList: string[] = [];
  public formModels: any = {};
  public selectedForm: string = 'Personal Injury';
  public selectedFormTypeId: number;
  public formGroups = {};
  public groupControls = {};
  public isLoading: boolean = false;
  public submittedForms = {};

  private snackBarStatus: BehaviorSubject<string>;
  public snackBarAdditionalText: BehaviorSubject<string>;
  private customControlUpdate: BehaviorSubject<boolean>;
  private formList: any[] = [
    { id: 1, name: 'PersonalInjury', displayName: 'Personal Injury', formName: 'Personal Injury', formTypeId: 1, icon: 'accessible' },
    { id: 2, name: 'SocialSecurity', displayName: 'Social Security', formName: 'Social Security', formTypeId: 2, icon: 'admin_panel_settings' },
    { id: 3, name: 'VeteranAffairs', displayName: 'Veteran Affairs', formName: 'Veteran Affairs', formTypeId: 3, icon: 'military_tech' },
    { id: 4, name: 'WorkersCompensation', displayName: "Worker's Comp", formName: "Worker's Compensation", formTypeId: 4, icon: 'engineering' },
  ];

  public signedFormFiles:any[] = [];

  public formSignature: string = null;

  constructor(public formBuilder: FormBuilder, private http: HttpClient) {
    this.snackBarStatus = new BehaviorSubject<string>('Form is Uploading...');
    this.customControlUpdate = new BehaviorSubject<boolean>(false);
  }

  public setCustomControlUpdate(val: boolean): void {
    this.customControlUpdate.next(val);
  }

  public getCustomControlUpdate(): Observable<boolean> {
    return this.customControlUpdate.asObservable();
  }

  public setValue(newValue, otherinfo?: string): void {
    if (otherinfo) {
      this.snackBarAdditionalText.next(otherinfo);
    }

    this.snackBarStatus.next(newValue);
  }

  public getValue(): Observable<string> {
    return this.snackBarStatus.asObservable();
  }

  public createFormModels(forms: any) {
    forms.forEach((f) => {
      if (f.formTypeId == FormTypes.PERSONAL_INJURY) {
        this.formModels[f.formName] = new PersonalInjuryForm();
      }
      else if (f.formTypeId == FormTypes.SOCIAL_SECURITY) {
        this.formModels[f.formName] = new SocialSecurityForm();
      }
      else if (f.formTypeId == FormTypes.VETERAN_AFFAIRS) {
        this.formModels[f.formName] = new VeteranAffairsForm();
      }
      else if (f.formTypeId == FormTypes.WORKERS_COMP) {
        this.formModels[f.formName] = new WorkersCompForm();
      }
      else if (f.formTypeId == FormTypes.MEDICARE) {
        this.formModels[f.formName] = new MedicareForm();
      }
      else if (f.formTypeId == FormTypes.CUSTOM) {
        // TO DO
      }
      this.formGroups[f.groupName] = this.formBuilder.group({});
    });

    this.createFormBuilders(forms);
  }

  public createCustomFormObj(groupName) {
    const f = this.formGroups[groupName];
    let form: CustomForm = new CustomForm();
    //Iterate through sections
    Object.keys(this.formGroups[groupName].controls).forEach(s => {
      let section: CustomSection = new CustomSection();
      section.name = s;
      //Get controls
      Object.keys(this.formGroups[groupName].controls[section.name].controls).forEach(c => {
        const iCtrl = this.formGroups[groupName].controls[section.name].controls[c];
        let ctrl: CustomControl = new CustomControl();
        const key: string = groupName + section.name + c;
        let hasKey: boolean = false;

        this.groupValues.forEach((value: any, k: string) => {
          if (k === key) {
            hasKey = true;
            this.groupValuesClearList.push(key);
          }
        });

        if (this.groupValues.get(key)) { // value is in groupValues its just null
          ctrl.value = this.groupValues.get(key);
        }
        else if (hasKey) {
          ctrl.value = "";
        }
        else {
          if (iCtrl.value?.name) {

            ctrl.fileValue = iCtrl.value;
          }
          else {
            ctrl.value = iCtrl.value;
          }
        }
        ctrl.name = c;
        section.controls.push(ctrl);
      });
      form.sections.push(section);
    });

    form.formName = groupName;
    form.groupName = groupName;

    //Add Signed File Objects
    form.signedFiles = this.signedFormFiles;

    return form;
  }

  private checkIfGroupControl(ctrlName) {
    if (this.groupControls[ctrlName]) {
      return true;
    }
    else {
      return false;
    }
  }


  public isFormGroupControlsValid(groupName): boolean {
    const BreakException = {};
    let wasInvalid: boolean = false;
    try{
      Object.keys(this.formGroups[groupName].controls).map((k)=> {
        Object.keys(this.formGroups[groupName].controls[k].controls).map((c) => {
          this.groupValues.forEach((value, key) => {
            const controlKey: string = groupName + k + c;

            if (controlKey === key) {
              // multi group controls can only have required validation
              if (this.formGroups[groupName].controls[k].controls[c].validator) {
                const gValue = this.groupValues.get(key);
                if (!gValue) {
                  wasInvalid = true;
                  throw BreakException;
                }
              }
            }
          });
        });
      });
    }
    catch (e) {
      if (wasInvalid) {
        return false;
      }
    }

    return true;
  }

  public createFormBuilders(forms: any) {
    forms.forEach((f) => {
      f.sections.forEach((s) => {
        // this.formGroups[f.groupName][s.groupName] = this.formBuilder.group({});
        var t = this.formBuilder.group({});
        s.controls.forEach((c) => {
          if (c.type == 'multiGroup') {

            const key = f.groupName + s.groupName + c.name;
            this.groupValues.set(key, null);
            var multiGroup = this.formBuilder.group({});
            c.controls.forEach((ctrl) => {
              multiGroup.addControl(ctrl.name, new FormControl('', []));
            });

            // Add Validators
            this.groupControls[c.name] = null;
            var validators = [];
            if(c.validators) {
              c.validators.forEach((v) => {
                if (v.validatorName == "required") {
                  validators.push(Validators.required);
                }
                else if (v.validatorName == "minLength") {
                  validators.push(Validators.minLength(v.valie));
                }
                else if (v.validatorName == "email") {
                  validators.push(Validators.email);
                }
              });
              multiGroup.setValidators(validators);
            }
            t.addControl(c.name, multiGroup);
          }
          else {
            var validatorArray = [];
            if (c.validators) {
              c.validators.forEach((v) => {
                if (v.validatorName == "required") {
                  validatorArray.push(Validators.required);
                }
                else if (v.validatorName == "minLength") {
                  validatorArray.push(Validators.minLength(v.valie));
                }
                else if (v.validatorName == "email") {
                  validatorArray.push(Validators.email);
                }
              });
            }
            t.addControl(c.name, new FormControl('', validatorArray));
          }
        });

        this.formGroups[f.groupName].addControl(s.groupName, t)
      });
    });
    this.isLoading = true;
  }

  public submitPersonalInjuryForm(form, lawFirmName) {
    let f = JSON.stringify(form);
    // return this.http.post('https://localhost:5001/api/form/' + lawFirmName + '/personalinjuryform', f, sendFormsHttpOptions);
    return this.http.post('https://lawformspro.com/service/api/form/' + lawFirmName + '/personalinjuryform', f, sendFormsHttpOptions);
  }

  public submitSocialSecurityForm(form, lawFirmName) {
    let f = JSON.stringify(form);
    return this.http.post('https://lawformspro.com/service/api/form/' + lawFirmName + '/socialsecurityform', f, sendFormsHttpOptions);
  }

  public submitVeteranAffairsForm(form, lawFirmName) {
    let f = JSON.stringify(form);
    return this.http.post('https://lawformspro.com/service/api/form/' + lawFirmName + '/veteranaffairsform', f, sendFormsHttpOptions);
  }

  public submitWorkersCompensationForm(form, lawFirmName) {
    let f = JSON.stringify(form);
    return this.http.post('https://lawformspro.com/service/api/form/' + lawFirmName + '/workerscompform', f, sendFormsHttpOptions);
  }

  public submitMedicareForm(form, lawFirmName) {
    let f = JSON.stringify(form);
    return this.http.post('https://lawformspro.com/service/api/form/' + lawFirmName + '/medicareform', f, sendFormsHttpOptions);
  }

  public submitCustomForm(form, lawFirmName, formId) {
    let f = JSON.stringify(form);
    // return this.http.post('https://localhost:5001/api/form/' + lawFirmName + '/customform/' + formId, f, sendFormsHttpOptions);
    return this.http.post('https://lawformspro.com/service/api/form/'+lawFirmName+'/customform/' + formId, f, sendFormsHttpOptions);
  }

  public getForms(lawFirmName) {
    // return this.http.get('https://localhost:5001/api/form/'+lawFirmName+'/getforms');
    return this.http.get('https://lawformspro.com/service/api/form/' + lawFirmName + '/getforms');
  }

  public submitError(error: string, lawFirmName) {
    let f = JSON.stringify({errorMessage:error});
    // return this.http.post('https://localhost:5001/api/form/' + lawFirmName + '/error', f, sendFormsHttpOptions);
    return this.http.post('https://lawformspro.com/service/api/form/'+lawFirmName+'/error', f, sendFormsHttpOptions);
  }
}
