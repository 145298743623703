import { formatDate } from '@angular/common';
import { Component, HostBinding, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { FormService } from '../../services/forms.service';

@Component({
  selector: 'app-multi-group-control',
  templateUrl: './multi-group-control.component.html',
  styleUrls: ['./multi-group-control.component.scss']
})
export class MultiGroupControlComponent implements OnInit {

  @HostBinding ("style.--secondary-company-color")
  public get secondaryCompanyColorAsStyle():any{
    return this.sanitizer.bypassSecurityTrustStyle(this.secondaryCompanyColor);
  }


  public columnSize: number = 4;

  public groupRowSize:number = 4;

  @ViewChild(MatTable) table: MatTable<any>;

  @ViewChild('multiPaginator') set paginator(pager: MatPaginator){
    if (pager) this.dataSource.paginator = pager;
  }

  @Input()
  groupControl: any;

  @Input()
  groupName: string;

  @Input()
  sectionName: string;

  @Input()
  secondaryCompanyColor: string = '';

  public dataSource= new MatTableDataSource<any>([]);

  public form: FormGroup;

  public displayedColumns: string[] = ['delete'];

  public controlKey: string = '';

  public isRequired: boolean = false;

  constructor( public formService:FormService,
    private controlContainer: ControlContainer,
    private sanitizer:DomSanitizer) { }

  ngOnInit(): void {

    this.controlKey = this.groupName+this.sectionName+this.groupControl.name;
    this.form = <FormGroup>this.controlContainer.control;

    this.groupControl.validators.forEach(v => {
      if (v.validatorName === 'required') {
        this.isRequired = true;
      }
    });

    if (this.isRequired) {
      this.groupControl.label += ' *';
    }

    this.groupControl.controls.forEach(element => {
      this.displayedColumns.push(element.name);
    });
  }

  public testGroupAdd(){

    var val = this.formService.formGroups[this.groupName].controls[this.sectionName].controls[this.groupControl.name].value;

    Object.keys(val).map(x=> {
      if(val[x] instanceof Date){
        val[x]=formatDate(val[x], 'MMMM dd, y', 'en-US');
      }
    });

    val['delete'] = this.createGuid();
    this.dataSource.data.push(
      val
    );

    this.formService.formGroups[this.groupName].controls[this.sectionName].controls[this.groupControl.name].reset();

    var d = this.dataSource.data;
    this.dataSource.data = [...d];

    this.setGroupControlValue();
  }

  private convertObjectToString(data): string{
    let val: string = '';
    for(let i = 0; i< data.length; i++){
      val += Object.values(data[i]) + ";";
    }

    return val;
  }

  private createGuid(){
    return this.guidGenerator(false) +
    this.guidGenerator(true) +
    this.guidGenerator(true)+
    this.guidGenerator(false);
  }

  private guidGenerator(s){
    var p = (Math.random().toString(16)+"000000000").substr(2,8);
    return s ? "-" + p.substr(0,4)+"-" + p.substr(4,4):p;
  }

  public deleteRow(id){
    var d = [];
    this.dataSource.data.forEach((r) => {
      if(r.delete != id){
        d.push(r);
      }
    });
    this.dataSource.data = [...d];

    this.setGroupControlValue();
  }

  private setGroupControlValue() {
    let data = JSON.parse(JSON.stringify(this.dataSource.data));

    data.forEach(x => {
      delete x["delete"];
    });

    this.formService.groupValues.set(this.controlKey, this.convertObjectToString(data));
    if (this.formService.groupControlValues?.[this.groupName]?.[this.sectionName]?.[this.groupControl.name] ) {
      this.formService.groupControlValues[this.groupName][this.sectionName][this.groupControl.name] = this.convertObjectToString(data);

    }
    else {
      this.formService.groupControlValues[this.groupName] = {};
      this.formService.groupControlValues[this.groupName][this.sectionName]= {};
      this.formService.groupControlValues[this.groupName][this.sectionName][this.groupControl.name] = this.convertObjectToString(data);
    }
  }
}
