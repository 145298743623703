import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PrivacyPolicyComponent} from './privacy-policy.component';
import { SharedModule } from '../../shared/shared.module';
import { FootersModule } from '../../blocks/footers/footers.module';
import { ActionsModule } from '../../blocks/actions/actions.module';
import { ShellModule } from '../../shell/shell.module';

@NgModule({
  declarations: [PrivacyPolicyComponent],
  imports: [
    CommonModule,SharedModule,FootersModule,ActionsModule,ShellModule
  ],
  exports:[PrivacyPolicyComponent]
})
export class PrivacyPolicyModule { }
