import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn:'root'
})

export class PricingTotalService{
  public selectedForms:BehaviorSubject<object>;
  public selectedFormSObj = {};
  public discountApplied:BehaviorSubject<boolean>;
  constructor(){
    this.selectedForms = new BehaviorSubject<object>(this.selectedFormSObj);
    this.discountApplied = new BehaviorSubject<boolean>(false);
  }

  public setSelectedForms(newValue):void{
    this.selectedFormSObj[newValue] = !this.selectedFormSObj[newValue];
    this.selectedForms.next(this.selectedFormSObj);
  }
  public getSelectedForms():Observable<object>{
    return this.selectedForms.asObservable();
  }

  public setDiscountApplied(val):void{
    this.discountApplied.next(val);
  }

  public getDiscountApplied():Observable<boolean>{
    return this.discountApplied.asObservable();
  }
}
