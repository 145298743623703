import { Component, OnInit, HostListener, HostBinding, ViewChild, ViewContainerRef, ComponentFactoryResolver, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'test-header-component',
  templateUrl: './testHeader.component.html',
  styleUrls: ['./customHeader.component.scss']
})
export class TestHeaderComponent implements OnInit {

  @Input()Header:any;
  public lawName: string;
  public altPhoneNumber:string='';
  static key ='TestHeaderComponent';

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.lawName = this.route.snapshot.params['lawName'];
    this.altPhoneNumber  = this.Header.phoneNumber.replace('(','');
    this.altPhoneNumber = this.altPhoneNumber.replace(')','');
    this.altPhoneNumber = this.altPhoneNumber.replace('-','');
    this.altPhoneNumber = 'tel:+1'+this.altPhoneNumber;
  }

}
