import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef } from '@angular/core';
import { AfterViewInit, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sign-up-input',
  templateUrl: './sign-up-input.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpInputComponent implements OnInit  {

  public windowWidth = 1440;
  public slideNumber=3;
  public slideCount=1;
  public slideName='slide-1';
  public fullName:string = 'Full Name';
  public loaded:boolean = false;

  ngOnInit(){

  }
  ngAfterViewInit(){
    this.loaded= true;
    this.cd.detectChanges();
  }

  constructor(private cd: ChangeDetectorRef) { }

  public getNextSlide(){
    if(this.slideCount < this.slideNumber){
      this.slideCount++;
      this.slideName='slide-'+this.slideCount;
    }
  }
  public getPreviousSlide(){
    if(this.slideCount > 1){
      this.slideCount--;
      this.slideName='slide-'+this.slideCount;
    }
  }
}
