<dc-navbar-default [darkLinks]="false"></dc-navbar-default>
<dc-page-header-wave
  [title]="'Thank you for contacting us!'"
  [subtitle]="
    'We will contact you shortly.'
  "
  [containerClass]="'pb-9'"
>
</dc-page-header-wave>
<dc-footer1></dc-footer1>
