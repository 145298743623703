import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dc-pricing-cards',
  templateUrl: './pricing-cards.component.html',
  styleUrls: ['./pricing-cards.component.scss']
})
export class PricingCardsComponent implements OnInit {
  plans = [
    {
      icon: 'box',
      best: true, // AVS: since this is the only plan right now, set as best. When we add more options make middle tier the 'best'
      plan: {
        name: 'Basic',
        price: 50,
        setupFee: 399,
        description:
          'Includes simple but powerful functionality for individuals and small law teams. You can upgrade on the way.'
      },
      features: [
        {
          name: '1 Template form included. Choose between: Social Security, Workers Comp, Personal Injury, and Veteran Affairs.'
        },
        {
          name: 'Directly upload matters to Prevail Case Management'
        },
        {
          name: 'Customized Logo/Header'
        },
        {
          name:'$50/mo for each additional template form.'
        }
      ]
    },
     {
       icon: 'box',
      best: false,
      plan: {
       name: 'Custom',
         price: 75,
         setupFee:499,
         description: 'For firms that have custom industry specific needs.'
       },
       features: [
         {
           name: '1 Custom form included.'
         },
         {
           name: 'Directly upload matters to Prevail Case Management'
         },
         {
           name: 'Customized Logo/Header'
         },
         {
           name: '$75/mo for each additional custom form.'
         }
       ]
     },
    // AVS: Temporarily commented out until we add custom forms
    // {
    //   icon: 'settings',
    //   plan: {
    //     name: 'Enterprise',
    //     price: 99.99,
    //     description: 'Everything you can get from a professional solution to keep your business on its way to success'
    //   },
    //   features: [
    //     {
    //       name: 'Cras justo odio'
    //     },
    //     {
    //       name: 'Dapibus ac facilisis in'
    //     },
    //     {
    //       name: 'Morbi leo risus'
    //     },
    //     {
    //       name: 'Porta ac consectetur ac'
    //     },
    //     {
    //       name: 'Vestibulum at eros'
    //     }
    //   ]
    // }
  ];

  constructor() {}

  ngOnInit() {}

}
