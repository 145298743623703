import { AdditionalInformation } from "../AdditionalInformation";
import { SpouseInformation } from "../SpouseInformation";
import { CaseInformation } from "./CaseInformation";
import { PersonalInformation } from "./PersonalInformation";

export class WorkersCompForm{
  personalInformation:PersonalInformation = new PersonalInformation();
  spouseInformation:SpouseInformation  = new SpouseInformation();
  caseInformation:CaseInformation = new CaseInformation();
  additionalInformation:AdditionalInformation = new AdditionalInformation();
}
