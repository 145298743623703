import { Component, OnInit } from '@angular/core';
import { FormService } from '../services/forms.service';

@Component({
  selector: 'form-loader',
  templateUrl: './form-loader.component.html',
  styleUrls: ['./form-loader.component.scss']
})
export class FormLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
