import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared';
import { CountersModule } from '../../blocks/counters/counters.module';
import { FootersModule } from '../../blocks/footers/footers.module';
import { UsualModule } from '../../blocks/usual/usual.module';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { ActionsModule } from '../../blocks/actions/actions.module';
import { AboutComponent } from './components/about/about.component';
import { AboutOverviewComponent } from './components/about-overview/about-overview.component';
import { AboutVideoComponent } from './components/about-video/about-video.component';
import { AboutSolutionsComponent } from './components/about-solutions/about-solutions.component';
import { AboutFeaturesComponent } from './components/about-features/about-features.component';
import { AboutCustomersComponent } from './components/about-customers/about-customers.component';
import { AboutTeamComponent } from './components/about-team/about-team.component';
import { AboutRegisterComponent } from './components/about-register/about-register.component';
import { Routes, RouterModule } from '@angular/router';
import { ShellModule } from 'src/app/shell/shell.module';
@NgModule({
  imports: [
    SharedModule,
    CountersModule,
    FootersModule,
    UsualModule,
    ActionsModule,
    SwiperModule,
    RouterModule,
    ShellModule,
  ],
  declarations: [
    AboutComponent,
    AboutOverviewComponent,
    AboutVideoComponent,
    AboutSolutionsComponent,
    AboutFeaturesComponent,
    AboutCustomersComponent,
    AboutTeamComponent,
    AboutRegisterComponent,
  ],
  exports: [AboutComponent],
})
export class AboutModule {}
