import { AdditionalInformation } from "../AdditionalInformation";
import { AccidentInformation } from "./AccidentInformation";
import { PersonalInformation } from "./PersonalInformation";
import { SpouseInformation } from "../SpouseInformation"

export class PersonalInjuryForm{
  personalInformation:PersonalInformation = new PersonalInformation();
  spouseInformation:SpouseInformation = new SpouseInformation();
  accidentInformation:AccidentInformation = new AccidentInformation();
  additionalInformation:AdditionalInformation = new AdditionalInformation();
};
