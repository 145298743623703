<mat-form-field appearance="fill" >
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [placeholder]="placeholder"
    (keypress)="onKey($event)"
    (keydown.backspace)="onBackspaceKeydown($event)"
    [formControl]="control"
    [required]="control.errors?.required"
  />
</mat-form-field>
