import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule as fModule, ReactiveFormsModule } from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import { HttpClientModule } from '@angular/common/http';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { TestHeaderComponent } from './testHeader.component';
import { DemoHeaderComponent } from './demo-header/demo-header.component';
import { AthenaTestHeaderComponent } from './athena-test-header/athena-test-header.component';
import { LawOfficeOfMLTHeaderComponent } from './law-office-of-mltheader/law-office-of-mltheader.component';
import { BerkelyDisabilityHeaderComponent } from './berkely-disability-header/berkely-disability-header.component';
import { AmericanDisabilityLawComponent } from './american-disability-law/american-disability-law.component';
import { GonzalezGoetzDisabilityHeaderComponent } from './gonzalez-goetz-disability-header/gonzalez-goetz-disability-header.component';
import { GonzalezGoetzMedicareHeaderComponent } from './gonzalez-goetz-medicare-header/gonzalez-goetz-medicare-header.component';
import { AshcraftLawHeaderComponent } from './ashcraft-law-header/ashcraft-law-header.component';
import { BettyLgJonesHeaderComponent } from './betty-lg-jones-header/betty-lg-jones-header.component';
import { TvcDischargeUpgradeHeaderComponent } from './tvc-discharge-upgrade-header/tvc-discharge-upgrade-header.component';
import { McmanesLawComponent } from './mcmanes-law/mcmanes-law.component';
import { StewartDisabilityLawHeaderComponent } from './stewart-disability-law-header/stewart-disability-law-header.component';
@NgModule({
  declarations: [
    TestHeaderComponent,
    DemoHeaderComponent,
    AthenaTestHeaderComponent,
    LawOfficeOfMLTHeaderComponent,
    BerkelyDisabilityHeaderComponent,
    AmericanDisabilityLawComponent,
    GonzalezGoetzDisabilityHeaderComponent,
    GonzalezGoetzMedicareHeaderComponent,
    AshcraftLawHeaderComponent,
    BettyLgJonesHeaderComponent,
    TvcDischargeUpgradeHeaderComponent,
    McmanesLawComponent,
    StewartDisabilityLawHeaderComponent],
  imports: [
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatGridListModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    fModule,
    ReactiveFormsModule,
    MatButtonModule,
    HttpClientModule,
    MatSnackBarModule
  ],
  exports: [
    TestHeaderComponent,
    DemoHeaderComponent,
    LawOfficeOfMLTHeaderComponent,
    BerkelyDisabilityHeaderComponent,
    GonzalezGoetzDisabilityHeaderComponent,
    GonzalezGoetzMedicareHeaderComponent,
    AshcraftLawHeaderComponent,
    BettyLgJonesHeaderComponent,
    TvcDischargeUpgradeHeaderComponent,
    McmanesLawComponent,
    StewartDisabilityLawHeaderComponent
  ],
  entryComponents:[
    TestHeaderComponent,
    DemoHeaderComponent,
    AthenaTestHeaderComponent,
    LawOfficeOfMLTHeaderComponent,
    BerkelyDisabilityHeaderComponent,
    GonzalezGoetzDisabilityHeaderComponent,
    GonzalezGoetzMedicareHeaderComponent,
    AshcraftLawHeaderComponent,
    BettyLgJonesHeaderComponent,
    TvcDischargeUpgradeHeaderComponent,
    McmanesLawComponent,
    StewartDisabilityLawHeaderComponent
  ]
})
export class CustomHeaderModule {}
