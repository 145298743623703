import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { FootersModule } from '../../blocks/footers/footers.module';
import { ActionsModule } from '../../blocks/actions/actions.module';
import { ShellModule } from '../../shell/shell.module';
import{NotFoundComponent} from './not-found.component';
import { ContactSuccessComponent } from '../contact-success/contact-success.component';

@NgModule({
  declarations: [NotFoundComponent, ContactSuccessComponent],
  imports: [
    CommonModule, SharedModule, FootersModule, ActionsModule, ShellModule
  ],
  exports:[NotFoundComponent]
})
export class NotFoundModule { }
