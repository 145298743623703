import{Injectable} from '@angular/core';
import{HttpClient, HttpHeaders} from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  responseType:'text' as 'json'
};
@Injectable({
providedIn:'root'
})

export class AuthService{
  constructor(private http: HttpClient){

  }

  public CheckCompanyAuth(company){
    return this.http.get('https://lawformspro.com/service/api/company/'+company+'/getcompanies',httpOptions);
  }
}
