export class PersonalInformation{
  salutation:string = '';
  firstName:string = '';
  middleName:string = '';
  lastName:string = '';
  dateOfBirth:Date = null;
  socialSecurityNumber:string = '';
  sex:string = '';
  address1:string = '';
  address2:string = '';
  city:string = '';
  state:string = '';
  zipCode:string = '';
  phoneNumber1:string = '';
  phoneNumber2:string = '';
  email:string = '';
  deceased:string = '';
  dateOfDeath:Date = null;
  referralSource:string = '';
}
