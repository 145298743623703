import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { PricingModule as PricingBlocksModule } from '../../blocks/pricing/pricing.module';
import { UsualModule } from '../../blocks/usual/usual.module';
import { CtaModule } from '../../blocks/cta/cta.module';
import { FootersModule } from '../../blocks/footers/footers.module';
import { FormsComponent } from './forms.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FormControlsModule } from '../../common/form-controls/formControls.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule as fModule, ReactiveFormsModule } from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import { HttpClientModule } from '@angular/common/http';
import{CustomHeaderModule} from './headers/customHeader.module';
import { FormService } from './services/forms.service';
import { FormLoaderComponent } from './form-loader/form-loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MultiGroupControlComponent} from  './controls/multi-group-control/multi-group-control.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormCompleteModalComponent } from '../modals/form-complete-modal/form-complete-modal.component';
import { SignatureModalComponent } from '../modals/signature-modal/signature-modal.component';
import { ContractControlComponent } from './controls/contract-control/contract-control.component';

@NgModule({
  declarations: [
    FormsComponent,
    FormLoaderComponent,
    MultiGroupControlComponent,
    FormCompleteModalComponent,
    SignatureModalComponent,
    ContractControlComponent],
  imports: [
    SharedModule,
    FootersModule,
    CtaModule,
    UsualModule,
    PricingBlocksModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatGridListModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormControlsModule,
    MatCheckboxModule,
    fModule,
    ReactiveFormsModule,
    MatButtonModule,
    HttpClientModule,
    CustomHeaderModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatTooltipModule
  ],
  exports: [FormsComponent],
  entryComponents:[FormsComponent]
})
export class FormsModule {}
