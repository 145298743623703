import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsConditionsComponent } from './terms-conditions.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FootersModule } from 'src/app/blocks/footers/footers.module';
import { ActionsModule } from 'src/app/blocks/actions/actions.module';
import { ShellModule } from 'src/app/shell/shell.module';


@NgModule({
  declarations: [TermsConditionsComponent],
  imports: [
    CommonModule,SharedModule,FootersModule,ActionsModule,ShellModule
  ],
  exports:[TermsConditionsComponent]
})
export class TermsConditionsModule { }
