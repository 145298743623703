
<div class="multi-group">
  <p class="multi-group-label" [class.invalid]="formService.submittedForms[groupName] && isRequired && (!this.formService.groupValues.get(this.controlKey) || this.formService.groupValues.get(this.controlKey).length == 0)">{{groupControl.label}}</p>
  <div class="multi-area"
  [class.multi-area--invalid]="formService.submittedForms[groupName] && isRequired && (!this.formService.groupValues.get(this.controlKey) || this.formService.groupValues.get(this.controlKey).length == 0)"
  [ngClass]="groupRowSize > 4 ? 'group-small-area':''">
    <div class="left-area">
      <form [formGroup]="formService.formGroups[groupName]" style="height:100%;position:relative;">
      <fieldset [formGroupName]="sectionName" style="width:100%">
        <fieldset [formGroupName]="groupControl.name" style="width:100%; height:100%">
          <mat-grid-list cols="1"
          rowHeight="65px"
          gutterSize="10px">
          <mat-grid-tile
            *ngFor="let ctrl of groupControl.controls"
            [colspan]="columnSize > 1 ? ctrl?.colSpan : 1"
            [rowspan]="ctrl?.rowSpan"
          >
          <mat-form-field *ngIf="ctrl?.typeId == '2'" appearance="fill" >
            <mat-label>{{ ctrl?.label }}</mat-label>
            <input matInput [formControlName]="ctrl?.name"  [placeholder]="ctrl?.placeholder"  [required]="formService.formGroups[groupName].controls[sectionName].controls[groupControl.name].controls[ctrl.name]?.errors?.required"/>
          </mat-form-field>
          <mat-form-field *ngIf="ctrl?.typeId == '1'" appearance="fill">
              <mat-label>{{ ctrl?.label }}</mat-label>
                <mat-select [formControlName]="ctrl.name" [required]="formService.formGroups[groupName].controls[sectionName].controls[groupControl.name]?.errors?.required">
                  <mat-option
                    *ngFor="let val of ctrl?.values"
                    [value]="val[ctrl?.valueText]">
                    {{ val[ctrl?.valueShown] }}
                  </mat-option>
                </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="ctrl?.typeId == '6'" appearance="fill">
            <mat-label>{{ ctrl?.label }}</mat-label>
            <input matInput [formControlName]="ctrl.name" [matDatepicker]="picker1"  [required]="formService.formGroups[groupName].controls[sectionName].controls[groupControl.name]?.errors?.required"/>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
          </mat-grid-tile>
          </mat-grid-list>
        </fieldset>
      </fieldset>
      <button type="button" style="position:absolute; bottom:0" mat-raised-button color="primary" class="add-group-btn" (click)="testGroupAdd()" >{{groupControl.multiGroupControlInformation.buttonText}}</button>
      </form>
    </div>
    <div class="right-area">
      <div class="no-inputs-area" [class.no-inputs-area--invalid]="formService.submittedForms[groupName] && isRequired && (!this.formService.groupValues.get(this.controlKey) || this.formService.groupValues.get(this.controlKey).length == 0)" *ngIf="dataSource?.data?.length == 0">
        <span>{{groupControl.multiGroupControlInformation.noItemsText}}</span>
      </div>
      <div class="mat-elevation-z8" *ngIf="dataSource?.data?.length > 0">
        <table mat-table [dataSource]="dataSource" style="width:100%">
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef> Delete </th>
            <td mat-cell *matCellDef="let element">
              <span (click)="deleteRow(element.delete)" class="material-icons">
                delete
              </span>
            </td>
          </ng-container>
          <ng-container *ngFor="let c of groupControl.controls" [matColumnDef]="c.name">
            <th mat-header-cell *matHeaderCellDef> {{c.label}} </th>
            <td mat-cell *matCellDef="let element"> {{element[c.name]}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator #multiPaginator [pageSize]="3" [length]="dataSource.data.length" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>
</div>
