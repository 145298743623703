import { Component, OnInit } from '@angular/core';
import { FormService } from 'src/app/pages/forms/services/forms.service';

@Component({
  selector: 'app-progress-snack-bar',
  templateUrl: './progress-snack-bar.component.html',
  styleUrls: ['./progress-snack-bar.component.scss']
})
export class ProgressSnackBarComponent implements OnInit {

  public snackBarText:string;
  public snackBarStatus:string;
  constructor(private formService:FormService) { }

  ngOnInit(): void {
    this.formService.getValue().subscribe((value) => {
      this.snackBarStatus = value;
      if(this.snackBarStatus=='loading'){
        this.snackBarText ='Form is Uploading...'
      }
      else if(this.snackBarStatus =='success'){
        this.snackBarText = "Form was Successfully Uploaded!"
      }
      else if(this.snackBarStatus == 'error') {
        const otherText: string = this.formService.snackBarAdditionalText.value;
        this.snackBarText ="Oops! Error Uploading. " + otherText + "Please contact us at hello@lawformspro.com if you have further issues."
      }
      else if(this.snackBarStatus=='error-payment'){
        this.snackBarText ="Oops! There was an error with the setup. Please contact us at hello@lawformspro.com"
      }
      else if(this.snackBarStatus=='form-validation-error') {
        this.snackBarText ="Oops! There are fields that need to be properly filled out."
      }
    });
  }

}
