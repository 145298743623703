<p class="multi-group-label">{{formObject?.label}}</p>
<div class="multi-area" [ngClass]="groupRowSize > 4 ? 'group-small-area':''">
  <div class="left-area" >
    <mat-grid-list cols="1"
    rowHeight="65px"
    gutterSize="10px">
    <mat-grid-tile
      *ngFor="let c of formObject?.controls"
      [colspan]="columnSize > 1 ? c?.colSpan : 1"
      [rowspan]="c?.rowSpan"
    >
      <mat-form-field *ngIf="c?.type == 'text'" appearance="fill" >
        <mat-label>{{ c?.label }}</mat-label>
        <input matInput [formControl]="controlList[c.name]"  [placeholder]="c?.placeholder" />
      </mat-form-field>
    </mat-grid-tile>
      <!--<mat-grid-tile colspan="1" rowSpan="1">
        <mat-form-field  appearance="fill" >
          <mat-label>Test Label</mat-label>
          <input formControlName="name" matInput placeholder="test placeholder"/>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowSpan="1">
        <mat-form-field  appearance="fill" >
          <mat-label>Test Label</mat-label>
          <input matInput formControlName="weight" placeholder="test placeholder"/>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowSpan="1">
        <mat-form-field  appearance="fill" >
          <mat-label>Test Label</mat-label>
          <input matInput formControlName="symbol" placeholder="test placeholder"/>
        </mat-form-field>
      </mat-grid-tile>-->
      <mat-grid-tile colspan="1" rowSpan="1">
        <button type="button" mat-raised-button color="primary" class="add-group-btn" (click)="MultiSelectControlGroupAdd()" >Add Medical Provider</button>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  <div class="right-area">
    <div class="no-inputs-area" *ngIf="dataSource.data.length == 0">
      <span>No Medical Providers</span>
    </div>
    <div class="mat-elevation-z8" *ngIf="dataSource.data.length > 0">
      <table mat-table [dataSource]="dataSource" style="width:100%">

        <!-- Position Column -->
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef> Delete </th>
          <td mat-cell *matCellDef="let element">
            <span (click)="deleteRow(element.delete)" class="material-icons">
              delete
            </span>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="weight">
          <th mat-header-cell *matHeaderCellDef> Weight </th>
          <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="symbol">
          <th mat-header-cell *matHeaderCellDef> Symbol </th>
          <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator #multiPaginator [pageSize]="4" [length]="dataSource.data.length" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>
