import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { FootersModule } from '../../blocks/footers/footers.module';
import { ActionsModule } from '../../blocks/actions/actions.module';
import { TestimonialsModule } from '../../blocks/testimonials/testimonials.module';
import { FaqsModule } from '../../blocks/faqs/faqs.module';
import { SlidersModule } from '../../blocks/sliders/sliders.module';
import { UsualModule } from '../../blocks/usual/usual.module';
import { CorporateComponent } from './corporate/corporate.component';
import { CorporateHeadingComponent } from './corporate-heading/corporate-heading.component';
import { CorporateDoBusinessComponent } from './corporate-do-business/corporate-do-business.component';
import { RouterModule } from '@angular/router';
import { ShellModule } from '../../shell/shell.module';

@NgModule({
  declarations: [
    CorporateComponent,
    CorporateHeadingComponent,
    CorporateDoBusinessComponent,
  ],
  imports: [
    SharedModule,
    FootersModule,
    ActionsModule,
    TestimonialsModule,
    FaqsModule,
    SlidersModule,
    UsualModule,
    RouterModule,
    ShellModule,
  ],
})
export class CorporateModule {}
