import { HostBinding } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CreditCardValidators } from 'angular-cc-library';
import { ProgressSnackBarComponent } from 'src/app/shared/progress-snack-bar/progress-snack-bar.component';
import { FormService } from '../forms/services/forms.service';
import { SignUp } from '../sign-up/interfaces/sign-up.interface';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { SignUpService } from '../sign-up/sign-up.service';
//import{Stripe} from 'stripe';
//import {CardValidation} from 'angular2-text-mask';
declare var Stripe:any;

@Component({
  selector: 'stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.scss']
})
export class StripePaymentComponent implements OnInit {

  @HostBinding("style.--snackbar-bg")
  public get snackbarBgAsStyle():any{
    return this.sanitizer.bypassSecurityTrustStyle(this.snackBarBg);
  }

  public card:any;
  private snackBarBg:string = '';
  public ccForm:any;
  public isLoading:boolean = false;

  public formClass:any;
  public example: any;
  public form:any;
  public resetButton:any;
  public error:any;
  public errorMessage:any;
  public savedErrors:any;
  private signUpObj:SignUp = {
    companyName:'',
    fullName:'',
    phoneNumber:'',
    email:'',
    agreeToTerms:false,
    forms:'',
  //  agreeToAutoPay:true,
   // stripeToken:''
  };

  constructor(public signUpService:SignUpService,public formService:FormService, private fb:FormBuilder,private router: Router, private sanitizer:DomSanitizer,private _snackBar: MatSnackBar) {
   }

  ngOnInit(): void {
    this.ccForm = this.fb.group({
      FullName: new FormControl('',[Validators.required]),
      CCNumber: new FormControl('',[CreditCardValidators.validateCCNumber]),
      CCExpiry: new FormControl('',[CreditCardValidators.validateExpDate]),
      Cvc: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]),
      ZipCode: new FormControl('', [Validators.required])
    });

    const stripe = Stripe('pk_live_51I2U5YDSBgSFpBZ5tHFZ9eBZuFqP9sXPriXHVwhX2CpK8u3MWi31V8I0v8KuxbO06kZhGh0poD5gufNzBZr3kqpf00YwSLh6aL');
    // Create `card` element that will watch for updates
    // and display error messages
    const style={
      base: {
        iconColor: '#a1a1a1',
        color: '#363636',
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        height:'35px',
        fontSmoothing: 'antialiased',

        ':-webkit-autofill': {
          color: '#fce883',
        },
        '::placeholder': {
          color: '#a1a1a1',
        },
      },
      invalid: {
        iconColor: '#FFC7EE',
        color: '#f04b54',
      },
    };

    const elements = stripe.elements();
    const card = elements.create('card',{style});
    card.mount('#card-element');
    //this.registerElements([card], 'example5');
    card.addEventListener('change', event => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    // Listen for form submission, process the form with Stripe,
    // and get the
    const paymentForm = document.getElementById('payment-form');
    paymentForm.addEventListener('submit', event => {
      event.preventDefault();
      this.isLoading=true;
      stripe.createToken(card).then(result => {
        if (result.error) {
          this.isLoading=false;
          console.log('Error creating payment method.');
          const errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
        } else {
          // At this point, you should send the token ID
          // to your server so it can attach
          // the payment source to a customer
          //this.signUpService.signUpObj.stripeToken = result.token.id;

          this.signUpService.signUpNewCompany(this.signUpService.signUpObj).subscribe((data)=>{

            this.isLoading=false;
            this.router.navigate(['/payment-success']);
          },
          (error)=>{
            console.log('Error');
            this.showErrorSnackBar();
            this.isLoading=false;
          });
        }
      });
    });
  }

  private showErrorSnackBar(){
    this._snackBar.dismiss();
      this.formService.setValue('error-payment');
      this._snackBar.openFromComponent(ProgressSnackBarComponent,{
        duration:5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass:['snackbar-error']
      });
      this.snackBarBg = '#ff9494';
  }
 /* submitCCForm(){
    console.log(this.ccForm);
    if(this.ccForm.valid){
      const stripe = Stripe('pk_test_51I2U5YDSBgSFpBZ56CMu2Sj4JFvVqySgyJ2JDmc1d0tFsiQNGhXTxY956eiYHnG2IebLluosHUpCff6G4aZd39ka00uaQ6QEr9');
      const card = {
        number:this.ccForm.value.CCNumber,
        exp_month:this.ccForm.value.CCExpiry.substring(0,2),
        exp_year:this.ccForm.value.CCExpiry.slice(-4),
        cvc:this.ccForm.value.Cvc
      }
      console.log(card);
      stripe.createToken({card}).then(result => {
        if (result.error) {
          console.log('Error creating payment method.');
          //const errorElement = document.getElementById('card-errors');
         // errorElement.textContent = result.error.message;
        } else {
          // At this point, you should send the token ID
          // to your server so it can attach
          // the payment source to a customer
          console.log('Token acquired!');
          console.log(result.token);
          console.log(result.token.id);
          this.signUpService.signUpObj.stripeToken = result.token.id;

          this.signUpService.signUpNewCompany(this.signUpService.signUpObj).subscribe((data)=>{
            //this.getNextSlide();
            this.isLoading=false;
            this.router.navigate(['/home']);
            console.log('test went well');
          },
          (error)=>{
            console.log('Error');
            this.isLoading=false;
          });
        }
      });
    }
  }*/

 /* createToken(){
    console.log(this.card);
    this.stripe.createToken(this.card).then(function(result){
      if(result.error){
        var errorElement = document.getElementById('card-erros');
        errorElement.textContent = result.error.message;
      }
      else{
        console.log(result.token);
        //stripeTokenHandler(result.token);
      }
    });
  }*/
}
