import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CorporateModule } from './pages/home/corporate.module';
import { ShellModule } from './shell/shell.module';
import { PricingModule } from './pages/pricing/pricing.module';
import { ContactModule } from './pages/contact/contact.module';
import { AboutModule } from './pages/about/about.module';
import { TermsModule } from './pages/terms/terms.module';
import { FormsModule } from './pages/forms/forms.module';
import { AboutComponent } from './pages/about/components/about/about.component';
import { NoCompanyModule } from './pages/no-company/no-company.module';
import { NotFoundModule } from './pages/not-found/not-found.module';
import { SignUpModule} from './pages/sign-up/sign-up.module';
import {PrivacyPolicyModule} from './pages/privacy-policy/privacy-policy.module';
import {TermsConditionsModule} from './pages/terms-conditions/terms-conditions.module';
import { StripePaymentModule } from './pages/stripe-payment/stripe-payment.module';
import {PaymentSuccessModule} from './pages/payment-success/payment-success.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ContactSuccessComponent } from './pages/contact-success/contact-success.component';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CorporateModule,
    PricingModule,
    ShellModule,
    ContactModule,
    AboutModule,
    TermsModule,
    FormsModule,
    NoCompanyModule,
    NotFoundModule,
    SignUpModule,
    PrivacyPolicyModule,
    TermsConditionsModule,
    StripePaymentModule,
    PaymentSuccessModule,
    MatTooltipModule,
    MatButtonModule,
    MatDialogModule
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
