import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tvc-discharge-upgrade-header',
  templateUrl: './tvc-discharge-upgrade-header.component.html',
  styleUrls: ['./tvc-discharge-upgrade-header.component.scss']
})
export class TvcDischargeUpgradeHeaderComponent implements OnInit {

  @Input()Header:any;
  public lawName: string;
  public altPhoneNumber:string='';
  static key ='TestHeaderComponent';

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.lawName = this.route.snapshot.params['lawName'];
    this.altPhoneNumber  = this.Header.phoneNumber.replace('(','');
    this.altPhoneNumber = this.altPhoneNumber.replace(')','');
    this.altPhoneNumber = this.altPhoneNumber.replace('-','');
    this.altPhoneNumber = 'tel:+1'+this.altPhoneNumber;
  }

}

