<dc-navbar-default [darkLinks]="false"></dc-navbar-default>
<dc-page-header-wave
  [title]="'Oops!'"
  [subtitle]="
    'Company Name was not found.'
  "
  [containerClass]="'pb-9'"
>
</dc-page-header-wave>
<div class="img-holder">
  <img class="no-company-img" src="assets/img/no_company.jpg" height="500"/>
</div>
<dc-footer1></dc-footer1>
