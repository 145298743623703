<section class="section terms">
  <div class="container pt-md-9">
    <h1>Dashcore Terms and Conditions</h1>
    <p class="text-secondary small bold">Last updated on August 03rd, 2018</p>

    <hr class="my-4" />

    <p class="bold">Welcome to Dashcore</p>
    <p>
      These Dashcore Terms and Conditions, is a legal agreement between Dashcore, Inc. and the person (you) who
      registered an Account using the Register Page available at our web site Lorem ipsum dolor sit amet, consectetur
      adipisicing elit. Aliquam autem commodi eum inventore nihil omnis sunt ullam voluptates. Consequuntur deleniti
      doloremque itaque mollitia officia optio, porro quaerat quasi quis tempora!
    </p>

    <p class="text-uppercase mb-5">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium, aliquam amet aspernatur at delectus dicta
      dignissimos esse excepturi facilis in ipsum laboriosam minima officiis quae quibusdam quos rem vero voluptatibus!
    </p>

    <h3>1. General Terms</h3>
    <p class="bold">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. A beatae ea eius facilis molestiae natus nemo nihil,
      nostrum possimus, quaerat qui repudiandae similique ullam. Eos id iure nam quam rerum. Lorem ipsum dolor sit amet,
      consectetur adipisicing elit. Asperiores autem doloremque fuga quisquam! Blanditiis cupiditate dicta, explicabo
      fugit hic iure nihil non odio praesentium qui quidem quos, repudiandae sapiente ut. Lorem ipsum dolor sit amet,
      consectetur adipisicing elit. Laudantium praesentium quam voluptatum! Aliquam, animi culpa, delectus deleniti,
      doloribus facere in inventore officia porro repellat reprehenderit similique temporibus unde. Aut, eveniet!
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aspernatur consequatur culpa cumque, debitis
      deserunt, ea error labore minima modi odio possimus qui quisquam, sapiente suscipit tenetur ullam veniam vitae?
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque eos impedit quisquam reprehenderit temporibus.
      Atque consectetur cum delectus dolores est exercitationem, fugit minus, mollitia neque, non perspiciatis quam
      suscipit vitae. Lorem ipsum dolor sit amet, consectetur adipisicing elit. At debitis deleniti facere fuga iure
      maxime nihil obcaecati perferendis perspiciatis quo, rem ut voluptas voluptate? A aspernatur exercitationem harum
      natus quasi? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium aut nobis quos rem? Adipisci
      amet deserunt distinctio eius id illum itaque odio officiis quia sequi similique, suscipit tempora, temporibus
      unde?
    </p>

    <h3>2. Ownership and License</h3>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores dolor eaque exercitationem id illum iste
      nihil reiciendis repudiandae totam vero. Animi doloremque doloribus esse fugiat nostrum quas quod sit unde. Lorem
      ipsum dolor sit amet, consectetur adipisicing elit. Ad aut deserunt dolorem dolorum ea, illum ipsa itaque nostrum
      obcaecati possimus. At eum fugiat hic illum impedit nisi quae quidem reprehenderit.
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet fugit id laudantium, maxime molestias natus
      nesciunt quibusdam. A accusantium esse, eveniet magnam officiis omnis soluta temporibus. Enim non officiis
      voluptatibus? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus explicabo illo nam nobis,
      provident quae qui quis repellat sed sunt. Dicta facilis laborum nemo non praesentium quis, ullam unde voluptatem!
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto, assumenda blanditiis cumque deserunt dicta
      doloribus ducimus, explicabo ipsum laudantium maiores nulla provident quod rem reprehenderit rerum sapiente soluta
      tempore voluptatibus.
    </p>

    <h3>3. Your Account</h3>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque distinctio eaque expedita facere voluptatum. Amet
      aperiam debitis ipsum nesciunt! Assumenda aut beatae consequatur cumque nam neque nulla officiis quaerat quia!
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. A ab autem dolorem expedita, facere fuga hic id impedit,
      ipsam iste nemo nobis nostrum, obcaecati repellendus repudiandae sit unde vel veniam?Lorem ipsum dolor sit amet,
      consectetur adipisicing elit. Architecto consequuntur cupiditate enim ex inventore iusto magni minus, nulla odio
      odit officia perspiciatis repellendus sed similique sit tempore totam voluptas voluptatum.
    </p>

    <h3>4. No Warranties</h3>
    <p class="text-uppercase">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eum expedita fuga natus? Eaque eius eos facere incidunt
      magni molestias nam nulla odio officiis quia quos, sequi ullam voluptate voluptates voluptatum. Lorem ipsum dolor
      sit amet, consectetur adipisicing elit. Harum numquam rerum saepe? At aut culpa eligendi hic libero nesciunt
      praesentium vitae voluptas! Architecto distinctio itaque necessitatibus officiis quaerat quas voluptates.
    </p>
    <p class="text-uppercase">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab dolores ducimus earum eos error expedita fuga impedit
      in incidunt inventore maiores molestiae possimus, praesentium saepe sint sunt ullam, unde veritatis!
    </p>

    <h3>Confidentiality</h3>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque autem, corporis delectus eligendi et eveniet
      excepturi fugit ipsum iure, nesciunt odit officiis quaerat quidem quo recusandae repellendus saepe veniam
      voluptate? Lorem ipsum dolor sit amet, consectetur adipisicing elit. At eos minima quo reiciendis sunt! A adipisci
      at, dolorem doloribus fuga harum, illum maxime molestiae perferendis quasi repudiandae sed similique voluptatum?
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci amet asperiores at consequuntur corporis
      doloremque eaque eos excepturi exercitationem expedita, illo ipsa odio, officiis quos sit soluta tempore vel
      voluptatibus?
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur cumque, eum fugiat inventore ipsum iure
      laudantium, minima nam necessitatibus nesciunt nulla placeat recusandae reiciendis rerum saepe similique suscipit
      totam. Rerum.
    </p>
  </div>
</section>
