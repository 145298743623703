<h1 class="title" mat-dialog-title>{{documentTitle}}</h1>
<div mat-dialog-content>
  <div>
    <object [data]="documentPath" type="application/pdf" width="100%" height="400px">
      <embed [src]="documentPath" type="application/pdf">
    </object>
   </div>
  <!-- <object style="display: block" attr.data="assets/firstTest.pdf" type="application/pdf" width="500px" height="600px"></object> -->
  <!-- <textarea class="signature__canvas">
   {{contract}}
  </textarea> -->
  <p class="disclaimer"><mat-icon class="info-icon">info</mat-icon> Upon submission, this document will be prefilled with  the data that was entered in the web form. By signing this document, I agree that the signature will be an electronic representation of my signature for all purposes, including legally binding contracts - just the same as pen and paper signature.</p>
  <div class="signature__control-area" [class.signature__control-area--signature]="!electronicAgreementNeeded">
    <div>
      <mat-form-field class="signature__signature-ctrl" *ngIf="electronicAgreementNeeded"  appearance="fill" >
        <mat-label>{{ 'Sign Full Name' }}</mat-label>
        <input matInput [(ngModel)]="signatureText" (ngModelChange)="updateCanvas($event)" />
      </mat-form-field>
    </div>
    <div class="signature__canvas-container">
      <span class="signature__canvas-label">Signature</span>
      <canvas class="signature__canvas" #signatureCanvas></canvas>
    </div>
  </div>
</div>
<div mat-dialog-actions class="footer">
  <button class="cancel-button" mat-raised-button (click)="cancelDialog()">Cancel</button>
  <button [disabled]="signatureText.length == 0" class="accept-signature-button" mat-raised-button (click)="saveSignature()">{{acceptButtonText}}</button>
</div>
