import{ChangeDetectionStrategy, ChangeDetectorRef,Component,ElementRef,Input} from '@angular/core';
import{customSvg} from './custom-svg';

@Component({
  selector:'core-icons',
  template:'<ng-content></ng-content>',
  styles:[':host::ng-deep svg {width:88px;height:88px}'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class IconComponent{

  constructor(private element: ElementRef){}

  @Input() set name(iconName:string){
    this.element.nativeElement.innerHTML = customSvg[iconName];
  }

  @Input() set color(color:string){
    this.element.nativeElement.firstElementChild.setAttribute('fill',color);
  }

  @Input() set size(size:string){
    this.element.nativeElement.firstElementChild.setAttribute(
      'style',
      'width:'+size+';height:'+size
    );
  }
}
