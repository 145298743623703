import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CorporateComponent } from './pages/home/corporate/corporate.component';
import { PricingComponent } from './pages/pricing/components/pricing/pricing.component';
import { ContactComponent } from './pages/contact/components/contact/contact.component';
import { AboutComponent } from './pages/about/components/about/about.component';
import { FormsComponent } from './pages/forms/forms.component';
import {AuthGuardService} from './shared/auth/auth-guard.service';
import { NoCompanyComponent } from './pages/no-company/no-company.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import {PrivacyPolicyComponent} from './pages/privacy-policy/privacy-policy.component';
import{TermsConditionsComponent} from './pages/terms-conditions/terms-conditions.component';
import { StripePaymentComponent } from './pages/stripe-payment/stripe-payment.component';
import { PaymentSuccessComponent } from './pages/payment-success/payment-success.component';
import { ContactSuccessComponent } from './pages/contact-success/contact-success.component';
const routes: Routes = [
  { path: 'home', component: CorporateComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'contact-success', component: ContactSuccessComponent },
  { path: 'about', component: AboutComponent },
  { path:'signup',component:SignUpComponent},
  { path:'stripe-test', component:StripePaymentComponent},
  { path:'signup-success', component:PaymentSuccessComponent},
  { path:'privacy-policy',component:PrivacyPolicyComponent},
  { path: 'terms', component: TermsConditionsComponent },
  { path:'nocompany', component: NoCompanyComponent},
  { path: 'forms/:lawName', component: FormsComponent, canActivate:[AuthGuardService] },
  { path: '', component: CorporateComponent },
  { path: '404', component: NotFoundComponent},
  { path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers:[AuthGuardService]
})
export class AppRoutingModule {}
