<form class="form">
  <div class="form-group">
    <label for="name">Name</label>
    <input type="text" class="form-control" id="name" placeholder="Name" />
  </div>
  <div class="form-group">
    <label for="email">Email</label>
    <input type="email" class="form-control" id="email" placeholder="Email" />
  </div>
  <div class="form-group">
    <label for="company">Company</label>
    <input type="text" class="form-control" id="company" placeholder="Company" />
  </div>

  <div class="form-group mt-5">
    <button class="btn btn-rounded btn-primary btn-block btn-lg text-center" type="submit">
      Send my application
    </button>
  </div>
</form>
