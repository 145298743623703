<mat-card class="pricing-total">
  <div class="pricing-item">
    <div class="pricing-left">
      <p>Up to 1 template forms (<span>{{includedFormCount}}</span>)</p>
    </div>
    <div class="pricing-right">
      <p>$169</p>
    </div>
  </div>
  <div class="pricing-item">
    <div class="pricing-left">
      <p>Additional forms (<span>{{additionalSelectedFormCount}}</span>)</p>
    </div>
    <div class="pricing-right">
      <p>${{additionalSelectedFormCount*25}}</p>
    </div>
  </div>
  <div class='pricing-divider'></div>
  <div class="pricing-body">
    <div class="pricing-left">
      <p>Total</p>
    </div>
    <div class="pricing-right">
      <p>${{total}}/mo</p>
    </div>
  </div>
  <span><i  [ngClass]="(discountSelected== true)?'discount-fee-active':''">+ ${{administratorFeePrice}} administrative setup fee.*</i></span>
  <span *ngIf="discountSelected" class='discount-text'><i>20% setup fee discount applied</i></span>
</mat-card>
