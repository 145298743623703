<mat-form-field [ngClass]="(control.status== 'INVALID')?'invalid-input':''" appearance="fill">
  <mat-label>Social Security Number</mat-label>
  <input
    matInput
    placeholder="Social Security Number"
    (keypress)="onKey($event)"
    (keydown.backspace)="onBackspaceKeydown($event)"
    [formControl]="control"
    [required]="control.errors?.required"
  />
</mat-form-field>
