import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import{ContactUs} from './interfaces/contact-us.interface';
const httpOptions = {

    headers: new HttpHeaders({ 'Accept':'text/plain','Content-Type': 'application/json'}),
    responseType:'text' as 'json'
};
@Injectable({
  providedIn:'root'
})
export class ContactUsService{
  constructor(private http:HttpClient){
  }


  public sendMessage(obj:ContactUs){
    let f = JSON.stringify(obj);
    return this.http.post<string>('https://lawformspro.com/service/api/contactus/sendmessage', f, httpOptions);
  }
}
