import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { SocialSecurityControlComponent } from './social-security/socialSecurityControl.component';
import { PhoneNumberControlComponent } from './phone-number/phoneNumberControl.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectGroupControlComponent } from './multi-select-group-control/multi-select-group-control.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
@NgModule({
  declarations: [SocialSecurityControlComponent, PhoneNumberControlComponent, MultiSelectGroupControlComponent],
  imports: [
    MatIconModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatGridListModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
  ],
  exports: [SocialSecurityControlComponent, PhoneNumberControlComponent,MultiSelectGroupControlComponent],
})
export class FormControlsModule {}
