<section class="section">
  <div class="container bring-to-front">
    <div
      class="shadow rounded text-center blanket blanket-dark alpha-8 text-contrast p-5 image-background cover"
      style="background-image: url(https://picsum.photos/350/200/?random&gravity=south)"
    >
      <div class="content">
        <div class="section-heading">
          <h2 class="text-contrast">Discover how DashCore works</h2>
        </div>

        <p class="handwritten highlight font-md">Play the video</p>

        <a href="https://www.youtube.com/watch?v=MXghcI8hcWU" class="btn btn-circle btn-lg btn-primary">
          <dc-feather [name]="'play'" class="ml-2" stroke-width="1"></dc-feather>
        </a>
      </div>
    </div>
  </div>
</section>
