import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { SignatureModalComponent } from 'src/app/pages/modals/signature-modal/signature-modal.component';
import { FormService } from '../../services/forms.service';
import { SignedFormFile } from '../../interfaces/signed-form-file';

@Component({
  selector: 'app-contract-control',
  templateUrl: './contract-control.component.html',
  styleUrls: ['./contract-control.component.scss']
})
export class ContractControlComponent implements OnInit {

  isSigned: boolean = false;

  @Input()
  signableFormControl: any;

  @Input()
  groupName: string;

  @Input()
  sectionName: string;

  @Input()
  secondaryCompanyColor: string = '';

  @Input()
  secondaryLabelColor: string = '';

  @Input()
  documentTitle: string = '';

  @Input()
  documentPath: string = '';

  private readonly electronicAgreementDocumentPath: string ='/assets/McManesElectronicRecordAndSignatureDisclosure.pdf';

  private readonly electronicAgreementDocumentTitle: string = 'Electronic Record And Signature Disclosure';

  private electronicAgreementNeeded: boolean = true;

  private electronicFile: SignedFormFile = new SignedFormFile();

  @HostBinding ("style.--secondary-company-color")
  public get secondaryCompanyColorAsStyle():any{
    return this.sanitizer.bypassSecurityTrustStyle(this.secondaryCompanyColor);
  }

  @HostBinding ("style.--secondary-label-color")
  public get secondaryLabelColorAsStyle():any{
    return this.sanitizer.bypassSecurityTrustStyle(this.secondaryLabelColor);
  }

  signedFile: SignedFormFile = new SignedFormFile();

  constructor(private sanitizer:DomSanitizer, public dialog: MatDialog, public formService: FormService) { }

  ngOnInit(): void {
    this.signedFile.name = this.signableFormControl.name;
    this.signedFile.documentSentTimeStamp = new Date(new Date().toISOString());
  }

  toggleSigned(): void {
    this.isSigned = !this.isSigned;

    if(this.isSigned) {
      this.signedFile.documentSignedTimeStamp = new Date(new Date().toISOString());
    }
    else if(!this.isSigned) {
      this.formService.formGroups[this.groupName].controls[this.sectionName].controls[this.signableFormControl.name].patchValue(null);

      //remove signed file from signedForm list
      this.removeSignedFile();
    }
  }


  openSignatureDialog(): void {

    let docTitle: string = this.documentTitle;
    let docPath: string = this.documentPath;

    if(!this.formService.formSignature){
      this.electronicAgreementNeeded = true;
      docPath = this.electronicAgreementDocumentPath;
      docTitle = this.electronicAgreementDocumentTitle;
    }
    else {
      this.electronicAgreementNeeded = false;
    }


    if (this.electronicAgreementNeeded) {
      this.electronicFile.documentOpenedTimeStamp = new Date(new Date().toISOString());
    }
    else {
      this.signedFile.documentOpenedTimeStamp = new Date(new Date().toISOString());
    }

    var config = {
      width: '60%',
      height: '80%',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: {documentTitle: docTitle, documentPath: docPath, electronicAgreementNeeded: this.electronicAgreementNeeded},
      panelClass: 'contract-modal'
    }

    const dialogRef = this.dialog.open(SignatureModalComponent, config);

    dialogRef.afterClosed().subscribe(result => {
      if (this.electronicAgreementNeeded) {
        if (result.signatureText) {
          this.formService.formSignature = result.signatureText;
          this.electronicAgreementNeeded = false;
          this.electronicFile.signature = result.signatureText;
          this.electronicFile.name = this.electronicAgreementDocumentTitle;
          this.electronicFile.documentSignedTimeStamp = new Date(new Date().toISOString());;
          this.electronicFile.signatureImage = result.signatureImage;
          this.formService.signedFormFiles.push(this.electronicFile);

          config = {
            width: '60%',
            height: '80%',
            maxWidth: '100vw',
            maxHeight: '100vh',
            data: {documentTitle: this.documentTitle, documentPath: this.documentPath, electronicAgreementNeeded: this.electronicAgreementNeeded},
            panelClass: 'contract-modal'
          }

          this.signedFile.documentOpenedTimeStamp = new Date(new Date().toISOString());
          const dialogRef2 = this.dialog.open(SignatureModalComponent, config);

          dialogRef2.afterClosed().subscribe( res2 => {
            if (res2.signatureText) {
              this.formService.formGroups[this.groupName].controls[this.sectionName].controls[this.signableFormControl.name].patchValue( res2.signatureText);
              this.toggleSigned();

              if (this.isSigned) {
                this.signedFile.signatureImage = res2.signatureImage;
                this.updateSignedFile();
              }
            }
            else {
              this.formService.formGroups[this.groupName].controls[this.sectionName].controls[this.signableFormControl.name].patchValue(null);
            }
          });
        }
      }
      else {
        if (result.signatureText) {
          this.formService.formGroups[this.groupName].controls[this.sectionName].controls[this.signableFormControl.name].patchValue(result.signatureText);
          this.toggleSigned();

          if (this.isSigned) {
            this.signedFile.signatureImage = result.signatureImage;
            this.updateSignedFile();
          }
        }
        else {
          this.formService.formGroups[this.groupName].controls[this.sectionName].controls[this.signableFormControl.name].patchValue(null);
        }
      }
    });
  }

  private updateSignedFile(): void {
    let index: number = -1;

    for ( let i = 0; i < this.formService.signedFormFiles.length; i ++) {
      if (this.formService.signedFormFiles[i].name === this.signableFormControl.name) {
        index = i;
        // Update signableForm at this index
        this.formService.signedFormFiles[i] = this.signedFile;
        break;
      }
    }

    // If file not found then add it to the signedFormsArray.
    if (index === -1) {
      this.formService.signedFormFiles.push(this.signedFile);
    }
  }

  private removeSignedFile(): void {
    let index: number = -1;

    for ( let i = 0; i < this.formService.signedFormFiles.length; i++) {
      if (this.formService.signedFormFiles[i].name === this.signableFormControl.name) {
        index = i;
        break;
      }
    }

    if (index > -1) {
      this.formService.signedFormFiles.splice(index, 1);
    }
  }
}
