<!-- ./Page header -->
<header
  class="header page section parallax image-background center-bottom cover blanket gradient gradient-purple-navy alpha-8 text-contrast"
  [style.background-image]="'url(assets/img/bg/grid.jpg)'"
>
  <div class="container overflow-hidden">
    <div class="row">
      <div class="col-md-10 col-lg-8">
        <h1 class="display-4 text-contrast bold">
          The ONLY trusted intake form uploader<span class="d-block light">for Prevail Case Management System</span>
        </h1>
        <p class="lead">
          LawFormsPro is a digital form service for the quickest matter uploads directly into the Prevail Case Management System
        </p>
        <p class="handwritten font-md highlight px-4">Get started now!</p>

        <nav class="nav mt-5">
          <a [routerLink]="['/pricing']" class="nav-link mr-3 btn btn-rounded btn-success">
            Start now
          </a>

          <!-- <a [routerLink]="['/about']" class="nav-link btn btn btn-rounded btn-contrast">
            How does it work?
          </a> -->
        </nav>
      </div>
    </div>
  </div>
</header>
