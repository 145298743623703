import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dc-terms-terms',
  templateUrl: './terms-terms.component.html',
  styleUrls: ['./terms-terms.component.scss']
})
export class TermsTermsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
