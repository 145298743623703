export const caseTypes = [
  { value: 1, description: 'Auto Accident' },
  { value: 2, description: 'Bicycle Accident' },
  { value: 3, description: 'Breach of contract' },
  { value: 4, description: 'Bus Accident' },
  { value: 5, description: 'Commercial Truck Accident' },
  { value: 6, description: 'Dog Bite' },
  { value: 7, description: 'Fall in Hole' },
  { value: 8, description: 'General Negligence' },
  { value: 9, description: 'Hit by Falling Object' },
  { value: 10, description: 'IME Cut Off' },
  { value: 11, description: 'Improper Bill Submission' },
  { value: 12, description: 'Limited Representation' },
  { value: 13, description: 'Medical Malpractice' },
  { value: 14, description: 'Misrep' },
  { value: 15, description: 'Motorcycle Accident' },
  { value: 16, description: 'NCCI' },
  { value: 17, description: 'Non Payment' },
  { value: 18, description: 'Pedestrian Accident' },
  { value: 19, description: 'Peer Review' },
  { value: 20, description: 'Premises Liability' },
  { value: 21, description: 'Products Liability' },
  { value: 22, description: 'Slip and Fall' },
  { value: 23, description: 'Trip and Fall' },
  { value: 24, description: 'UCR' },
  { value: 25, description: 'UNC (Improper Endorsement)' },
  { value: 26, description: 'Under Investigation' },
  { value: 27, description: 'Wrongful Death' },
];
