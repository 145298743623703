<dc-navbar-default [darkLinks]="false"></dc-navbar-default>
<dc-page-header-wave
  [title]="'About LawForms'"
  [subtitle]="
    'LawForms is a digital form service that allows you lawfirm to upload intake forms directly into Prevail at the click of a button.'
  "
>
</dc-page-header-wave>
<dc-about-overview></dc-about-overview>
<dc-about-video></dc-about-video>
<dc-about-solutions></dc-about-solutions>
<dc-counter4></dc-counter4>
<dc-about-features></dc-about-features>
<dc-about-customers></dc-about-customers>
<dc-about-team></dc-about-team>

<dc-trust-us [title]="'They trust us'"></dc-trust-us>
<dc-developer-designer></dc-developer-designer>

<dc-about-register></dc-about-register>

<dc-footer1></dc-footer1>
