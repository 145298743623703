import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FaqsService {
  constructor() {}

  getFaqs(): Observable<any> {
    return of([
      {
        question: 'What does the package include?',
        answer:
          'When you pay for LawFormsPro, you get intake templates as seen in the demo images. The links to the templates can be sent to your clients, filled out online, and once submitted, they will appear on your end as a matter into the Prevail Case Management System. We include 1 template in our package complete with formatted email backups sent to the email of your choice. Additional templates are $50-75/month per template.'
      },
      {
        question: 'What is the typical turn around time for my upload site to be up and running?',
        answer:
          'Once your templates are selected and you have completed the sign up process, we will need your logo and colors which can be sent via email. There is some additional setup behind the scenes, however, a standard time could be a few hours.'
      },
      {
        question: 'How do I customize my upload site?',
        answer:
          'We will contact you once you have chosen your templates, you can then email us your logo and custom colors you want for your site.'
      },
      {
        question: 'Can I suggest a new feature?',
        answer:
          "Definitely <span class='bold'>Yes</span>, you can contact us to let us know your needs. If your suggestion represents value to us both, we can include it as a part of the package or you can request a custom feature for an extra cost. Please note it could take some time in order for the feature to be implemented."
      }
    ]);
  }
}
