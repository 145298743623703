<button class="previous-button" mat-raised-button (click)="signUpService.getPreviousSlide()">Previous</button>
<mat-card class="payment-card">
  <h2>Payment Summary</h2>
  <div class="summary-row">
    <div class="summary-left">
      <p><i>(Basic)</i> First Monthly Payment</p>
    </div>
    <div class="summary-right">
      <p>${{signUpService.monthlyPrice}}/mo</p>
    </div>
  </div>
  <div class="divider"></div>
  <div class="summary-row add-margin">
    <div class="summary-left">
      <p><i>(Administrative setup fee)</i> One-Time Payment</p>
    </div>
    <div class="summary-right">
      <p><span class="discount-percent"><i>20% off</i></span><del class="old-price">$999</del> <ins class="discount-price">$799</ins></p>
    </div>
  </div>
  <!--<div class="summary-row">
    <div class="summary-left">
      <p>Introductory Administrative Fee Discount</p>
    </div>
    <div class="summary-right">
      <p class="discount">20%</p>
    </div>
  </div>-->
  <div class="divider"></div>
  <div class="summary-row">
    <div class="summary-left">
      <p class="total-text">Today You Pay</p>
    </div>
    <div class="summary-right">
      <p class="total-text">${{signUpService.monthlyPrice+799}}</p>
    </div>
  </div>
</mat-card>
<!--<mat-card class="payment-card">
  <h2>Payment Method</h2>
  <form [formGroup]="ccForm" (ngSubmit)="submitCCForm()" data-response-message-animation="slide-in-up">
    <mat-grid-list cols="2" rowHeight="85px" gutterSize="5px" style="width:100%; height:100%;">
      <mat-grid-tile rowspan="1" colspan="2">
        <mat-form-field appearance="fill">
          <mat-label>Card Holder's Full Name</mat-label>
          <input formControlName="FullName" matInput required>
        </mat-form-field>
        <mat-error class="form-error" *ngIf="ccForm.controls.FullName?.errors?.required &&(ccForm.controls.FullName?.dirty || ccForm.controls.FullName?.touched)">Cardholder Name is required.</mat-error>
      </mat-grid-tile>
      <mat-grid-tile rowspan="1" colspan="2">
        <mat-form-field appearance="fill">
          <mat-label>Card Number</mat-label>
          <input formControlName="CCNumber" type="tel" ccNumber #ccNumber="ccNumber" matInput>
        </mat-form-field>
        <mat-error class="form-error" *ngIf="ccForm.controls.CCNumber?.errors?.ccNumber &&(ccForm.controls.CCNumber?.dirty || ccForm.controls.CCNumber?.touched)">Please enter a vaild credit card number.</mat-error>
       <span class="scheme">{{ccNumber.resolvedScheme$ | async}}</span>-->
      <!--</mat-grid-tile>
      <mat-grid-tile rowspan="1" colspan="1">
        <mat-form-field appearance="fill">
          <mat-label>Expiration Date</mat-label>
          <input formControlName="CCExpiry" id="cc-exp-date" type="tel" autocomplete="cc-exp" matInput ccExp>
        </mat-form-field>
        <mat-error class="form-error" *ngIf="ccForm.controls.CCExpiry?.errors?.expDate &&(ccForm.controls.CCExpiry?.dirty || ccForm.controls.CCExpiry?.touched)">Please enter a valid expiration date.</mat-error>
      </mat-grid-tile>
      <mat-grid-tile rowspan="1" colspan="1">
        <mat-form-field appearance="fill">
          <mat-label>CVV/CVC</mat-label>
          <input formControlName="Cvc" id="cc-cvc" type="tel" autocomplete="off" matInput ccCVC>
        </mat-form-field>
        <mat-error class="form-error" *ngIf="ccForm.controls.Cvc?.errors?.required &&(ccForm.controls.Cvc?.dirty || ccForm.controls.Cvc?.touched)">Please enter a valid CVV/CVC.</mat-error>
      </mat-grid-tile>
      <mat-grid-tile rowspan="1" colspan="1">
        <mat-form-field appearance="fill">
          <mat-label>Zip Code</mat-label>
          <input formControlName="ZipCode" matInput required>
        </mat-form-field>
        <mat-error class="form-error" *ngIf="ccForm.controls.ZipCode?.errors?.required &&(ccForm.controls.ZipCode?.dirty || ccForm.controls.ZipCode?.touched)">Please enter a zip code.</mat-error>
      </mat-grid-tile>
    </mat-grid-list>
    <p class="secure-text"><span class="material-icons secure-lock">
      https
      </span>Your transaction is secured with SSL encryption</p>
    <button [ngClass]="(isLoading== true)?'color-remover':''"  id="contact-submit" class="btn btn-primary btn-rounded form-button" mat-raised-button>
      Submit Payment
      <mat-spinner class="loading-spinner" *ngIf="isLoading" diameter="30"></mat-spinner>
    </button>
  </form>
</mat-card>-->
<!--Example 1-->
<mat-card class="payment-card">
  <h2>Payment Information</h2>
  <form action="/charge" method="post" id="payment-form">
    <div class="form-row">
        <label class="card-title" for="card-element">
            Credit or debit card
        </label>
        <div id="card-element">
            <!-- a Stripe Element will be inserted here. -->
        </div>

        <!-- Used to display form errors -->
        <div id="card-errors" role="alert"></div>
    </div>

    <div class="spinner-row">
      <mat-spinner  class="loading-spinner" *ngIf="isLoading" diameter="30"></mat-spinner>
      <input [disabled]="isLoading" [ngClass]="isLoading?'loading-text':''" type="submit" class="submit" [value]="'Pay $'+(signUpService.monthlyPrice+799)">
    </div>
  </form>
</mat-card>
