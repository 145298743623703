import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dc-corporate-do-business',
  templateUrl: './corporate-do-business.component.html',
  styleUrls: ['./corporate-do-business.component.scss']
})
export class CorporateDoBusinessComponent implements OnInit {
  cards = [
    { img: 'time', title: 'Save time', animation: 'fade-up', subtitle: "No need for employees to manually enter data. Uploads will automatically appear as matters into the Prevail Case Management System" },
    { img: 'done', title: 'Get things done quicker', animation: 'fade-up', subtitle: "Send a link to your client to fill out the digital intake form. Once the client hits submit, the matter will be entered in the Prevail Case Management System."  },
    { img: 'grow', title: 'Save Money', animation: 'fade-up', subtitle: "No more paper intakes or time spent on data entry."  },
    { img: 'goals', title: 'Secure data', animation: 'fade-up', subtitle: "Your data will be securely sent to the Prevail Case Management System and entered as a matter. You will also get formatted email backups."  }
  ];

  constructor() {}

  ngOnInit() {}
}
