<section>
  <div class="container">
    <!--<div class="discount-card">
      <span>20% off of Administrative Setup Fee Today</span>
    </div>-->
    <div class="section-heading text-center">
      <h2 class="mb-2">Simple and affordable pricing</h2>
      <p class="lead text-secondary">Sign up below:</p>
    </div>

    <div class="row center-content">
      <div class="col-md-4 mt-5" *ngFor="let plan of plans">
        <div class="card text-center">
          <div
            [class]="
              'pricing card-header d-flex align-items-center flex-column ' +
              (plan.best ? 'bg-primary-gradient text-contrast' : 'bg-light-gradient')
            "
          >
            <h4 [class.text-contrast]="plan.best">{{ plan.plan.name }}</h4>

            <div class="pricing-value">
              <span class="price bold">{{ plan.plan.price }}</span>
            </div>

            <div class="pricing-value fee">
              <span class="setup-fee"><del>$999</del><ins class="setup-fee-insert">${{ plan.plan.setupFee }}</ins></span>
            </div>

            <p>{{ plan.plan.description }}</p>
          </div>

          <ul class="list-group list-group-flush">
            <li class="list-group-item {{ feature.class }}" *ngFor="let feature of plan.features">
              {{ feature.name }}
            </li>
          </ul>

          <div class="card-body">
            <a [routerLink]="['/contact']" class="btn {{ plan.best ? 'btn-primary' : 'btn-outline-secondary' }}">
              Get Started
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
