<section class="singl-testimonial shadow">
  <div class="container-fluid py-0">
    <div class="row align-items-center gradient gradient-blue-navy text-contrast">
      <div class="col-md-3 mx-auto py-4 py-md-0">
        <p class="rounded-pill py-2 px-4 text-uppercase mb-0 badge badge-contrast">LawFormsPro is great for</p>

        <p class="font-md spacing bold mt-1 text-contrast" style="line-height: 40px;">
          Anyone who is looking to digitize their intake forms
        </p>

        <hr class="my-4" />
        <!--<div class="small text-contrast">
          <span class="bold d-block">Benenati Team,</span>
          Doing great since 2018
        </div>-->
      </div>

      <div
        class="col-12 col-md-7 image-background cover"
        [style.background-image]="'url(assets/img/testimonials/1.jpg)'"
      ></div>
    </div>
  </div>
</section>
