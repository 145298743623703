import { Component, HostBinding, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { FormService } from 'src/app/pages/forms/services/forms.service';

@Component({
  selector: 'multi-select-group-control',
  templateUrl: './multi-select-group-control.component.html',
  styleUrls: ['./multi-select-group-control.component.scss']
})
export class MultiSelectGroupControlComponent implements OnInit {

  @Input() formObject:any;
  @Input() formName:string;
  @Input() formGroup:string;
  @Input() sectionGroupName:string;

  private secondaryCompanyColor='';
  private secondaryLabelColor='';

  public columnSize: number = 4;
  public groupRowSize:number = 4;
  public dataSource= new MatTableDataSource<any>([]);
  public form:FormGroup;
  public controls;
  public displayedColumns: string[] = [];
  public controlList = {}
  @HostBinding ("style.--secondary-company-color")
  public get secondaryCompanyColorAsStyle():any{
    return this.sanitizer.bypassSecurityTrustStyle(this.secondaryCompanyColor);
  }

  @HostBinding("style.--secondary-company-Label")
  public get secondarylabelAsStyle():any{
    return this.sanitizer.bypassSecurityTrustStyle(this.secondaryLabelColor);
  }


  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (event) {
      if (event.target.innerWidth > 1150) {
        this.columnSize = 4;
        this.groupRowSize=4;
      } else if (
        event.target.innerWidth <= 1150 &&
        event.target.innerWidth > 880
      ) {
        this.columnSize = 3;
        this.groupRowSize=4;
      } else if (
        event.target.innerWidth <= 880 &&
        event.target.innerWidth > 700
      ) {
        this.columnSize = 2;
        this.groupRowSize = 8;
      } else if (event.target.innerWidth <= 700) {
        if (this.columnSize > 1) {
          //this.isOpen = false;
        }
        this.columnSize = 1;
        this.groupRowSize=8;
      }
    } else {
      if (window.innerWidth > 1150) {
        this.columnSize = 4;
        this.groupRowSize = 4;
      } else if (window.innerWidth <= 1150 && window.innerWidth > 880) {
        this.columnSize = 3;
        this.groupRowSize=4;
      } else if (window.innerWidth <= 880 && window.innerWidth > 700) {
        this.columnSize = 2;
        this.groupRowSize=8;
      } else if (window.innerWidth <= 700) {
        if (this.columnSize > 1) {
          //this.isOpen = false;
        }
        this.columnSize = 1;
        this.groupRowSize=8;
      }
    }
  }

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild('multiPaginator') set paginator(pager: MatPaginator){
    if (pager) this.dataSource.paginator = pager;
  }

  constructor(public formService:FormService, private controlContainer:ControlContainer,private sanitizer:DomSanitizer) {
   }

  ngOnInit(): void {
    this.onResize();

    this.form = <FormGroup>this.controlContainer.control;
    var c =<FormControl>this.form.get(this.sectionGroupName);
    this.controls = <FormControl>c.get(this.formObject.name);

    Object.keys(this.controls.controls).forEach(key => {
      this.controlList[key] = this.controls.controls[key];
    })

    this.formObject.controls.forEach((c)=>{
      this.displayedColumns.push(c.name);
    });
  }

  public deleteRow(id){
    var d = [];
    this.dataSource.data.forEach((r) => {
      if(r.delete != id){
        d.push(r);
      }
    });

    this.dataSource.data = [...d];
    this.setFormControlValue();
  }

  private setFormControlValue(){
    let val = '';
    this.dataSource.data.forEach(d => {
      Object.keys(d).forEach(o =>{
        if(o != 'delete'){
          const n = o.charAt(0).toUpperCase() + o.slice(1);
          val += n +": " +d[o]+', ';
        }
      });
      val = val.slice(0, -2);
      val += "; ";
    });

    val = val.slice(0,-1);

    this.formService.groupControls[this.formObject.name] = val;
  }

  private createGuid(){
    return this.guidGenerator(false) +
    this.guidGenerator(true) +
    this.guidGenerator(true)+
    this.guidGenerator(false);
  }

  private guidGenerator(s){
    var p = (Math.random().toString(16)+"000000000").substr(2,8);
    return s ? "-" + p.substr(0,4)+"-" + p.substr(4,4):p;
  }
  public MultiSelectControlGroupAdd(){
    var val = this.formService.formGroups['PersonalInjury'].controls['personalInformation'].controls['medicalProviders'].value;
    val['delete'] = this.createGuid();
    this.dataSource.data.push(
      val
    );
    var d = this.dataSource.data;
    this.dataSource.data = [...d];

    this.setFormControlValue();
    this.formService.formGroups['PersonalInjury'].controls['personalInformation'].controls['medicalProviders'].reset();
  }

}
