import { Component, OnInit, HostListener, Input } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { FormService } from 'src/app/pages/forms/services/forms.service';

@Component({
  selector: 'social-security-control-component',
  templateUrl: './socialSecurityControl.component.html',
  styleUrls: ['./socialSecurityControl.component.scss'],
})
export class SocialSecurityControlComponent implements OnInit {

  @Input() formObject: any;
  @Input() formGroupName:string;
  @Input() sectionGroupName:string;
  @Input() formName:string;

  public form:FormGroup;
  public control:FormControl;

  private lastLength: number = 0;
  constructor(public formService:FormService,private controlContainer:ControlContainer) {}

  ngOnInit() {
    this.form = <FormGroup>this.controlContainer.control;
    var c =<FormControl>this.form.get(this.sectionGroupName);
    this.control = <FormControl>c.get(this.formObject.name);

    this.formService.getCustomControlUpdate().subscribe((value)=>{
      if(value && this.formService.selectedForm == this.formName){
        this.control.markAsTouched();
      }
    })
  }

  public onKey($event) {
    var length = $event.target.value.length;

    if (isNaN(Number($event.key)) || length == 11) {
      $event.preventDefault();
    }

    if (length == 2) {
      $event.target.value += $event.key + '-';
      $event.preventDefault();
    } else if (length == 3) {
      $event.target.value += '-' + $event.key;
      $event.preventDefault();
    } else if (length == 5) {
      $event.target.value += $event.key + '-';
      $event.preventDefault();
    } else if (length == 6) {
      $event.target.value += '-' + $event.key;
      $event.preventDefault();
    }
  }
  public onBackspaceKeydown($event) {
    var length = $event.target.value.length;
    if (length == 8) {
      $event.target.value = $event.target.value.substring(0, 7);
    } else if (length == 5) {
      $event.target.value = $event.target.value.substring(0, 4);
    }
  }
}
