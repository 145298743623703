import { Component, OnInit } from '@angular/core';
import { PricingTotalService } from './pricing-total.service';

@Component({
  selector: 'pricing-total',
  templateUrl: './pricing-total.component.html',
  styleUrls: ['./pricing-total.component.scss']
})
export class PricingTotalComponent implements OnInit {

  public selectedForms:boolean[]=[];
  public selectedFormCount:number = 0;
  public includedFormCount:number = 0;
  public additionalSelectedFormCount:number=0;
  public additionalFormPrice=0;
  public total:number=169;
  public discountSelected:boolean = true;
  public administratorFeePrice = 799;
  constructor(private pricingService:PricingTotalService) { }

  ngOnInit(): void {
    this.pricingService.getSelectedForms().subscribe((forms)=>{
      this.selectedFormCount = 0;
      Object.keys(forms).forEach(f=>{
        if(forms[f]){
          this.selectedFormCount++;
        }
      });

      if(this.selectedFormCount == 1){
        this.includedFormCount = this.selectedFormCount;
      }
      else{
        this.includedFormCount = 2;
      }

      if(this.selectedFormCount-1 > 0){
        this.additionalSelectedFormCount = this.selectedFormCount-1;
      }
      else{
        this.additionalSelectedFormCount = 0;
      }
      this.additionalFormPrice = this.additionalSelectedFormCount * 25;
      this.total = 169 + this.additionalFormPrice;
    });

    this.pricingService.getDiscountApplied().subscribe((d)=>{
      this.discountSelected = true;

     /* if(this.discountSelected){
        this.administratorFeePrice = this.administratorFeePrice *.8;
      }
      else{
        this.administratorFeePrice = 625;
      }*/
      this.administratorFeePrice=799;
    });
  }

}
