<div class="contract-ctrl" [class.contract-ctrl--signed]="isSigned">
  <mat-icon *ngIf="isSigned" class="contract-ctrl__header-icon">check_box</mat-icon>
  <mat-icon *ngIf="!isSigned" class="contract-ctrl__header-icon">check_box_outline_blank</mat-icon>
  <p class="contract-ctrl__title">{{documentTitle}}
    <span class="contract-ctrl__signed" *ngIf="isSigned">(signed)</span>
  </p>
  <mat-icon class="contract-ctrl__remove" *ngIf="isSigned" (click)="toggleSigned()">disabled_by_default</mat-icon>
  <button *ngIf="!isSigned" class="contract-ctrl__view-document" type="button" mat-raised-button color="primary" matSuffix (click)="openSignatureDialog()">
    Click to View
  </button>
  <div *ngIf="(formService.formGroups[groupName].controls[sectionName].controls[signableFormControl.name].invalid && (formService.formGroups[groupName].controls[sectionName].controls[signableFormControl.name].dirty || formService.formGroups[groupName].controls[sectionName].controls[signableFormControl.name].touched))">
    <div *ngIf="formService.formGroups[form.groupName].controls[section.groupName].controls[c.name].errors?.required">
     Form is required.
    </div>
  </div>
</div>
