import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { PricingModule as PricingBlocksModule } from '../../blocks/pricing/pricing.module';
import { FaqsModule } from '../../blocks/faqs/faqs.module';
import { UsualModule } from '../../blocks/usual/usual.module';
import { CtaModule } from '../../blocks/cta/cta.module';
import { FootersModule } from '../../blocks/footers/footers.module';
import { PricingComponent } from './components/pricing/pricing.component';
import { PricingIncludeComponent } from './components/pricing-include/pricing-include.component';
import { ShellModule } from 'src/app/shell/shell.module';

@NgModule({
  declarations: [PricingComponent, PricingIncludeComponent],
  imports: [
    SharedModule,
    FootersModule,
    CtaModule,
    UsualModule,
    FaqsModule,
    PricingBlocksModule,
    ShellModule,
  ],
  exports: [PricingComponent],
})
export class PricingModule {}
