import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { FormService } from '../../forms/services/forms.service';


@Component({
  selector: 'app-signature-modal',
  templateUrl: './signature-modal.component.html',
  styleUrls: ['./signature-modal.component.scss'],

})
export class SignatureModalComponent implements OnInit, AfterViewInit {
  @ViewChild('signatureCanvas')
  canvas: ElementRef<HTMLCanvasElement>;

  context: CanvasRenderingContext2D;

  signatureText: string = '';

  contract: string = '1. I hereby employ A. Spencer McManes, J.r., P.C. ("THE FIRM"), as my attorneys, to represent me against all persons or entities liable for  the injuries I sustained on or about the {dayOfMonth} day of {month}, 20{year}.\n\n';

  documentTitle: string = '';

  documentPath: SafeResourceUrl;

  acceptButtonText: string = 'Create Signature and Accept';

  electronicAgreementNeeded: boolean = true;

  constructor(public dialogRef: MatDialogRef<SignatureModalComponent>,
    private sanitizer:DomSanitizer, private formService: FormService,
    @Inject(MAT_DIALOG_DATA) public data: {documentTitle: string, documentPath: string, electronicAgreementNeeded: boolean}) {
      this.documentPath = this.sanitizer.bypassSecurityTrustResourceUrl(data.documentPath);
      this.documentTitle = data.documentTitle;
      this.electronicAgreementNeeded = data.electronicAgreementNeeded;

      if (!this.electronicAgreementNeeded) {
        this.acceptButtonText = 'Agree and Sign';
      }
    }

  ngAfterViewInit(): void {

    this.context = this.canvas.nativeElement.getContext('2d');

    if (this.formService.formSignature && this.formService.formSignature.length > 0) {
      this.signatureText = this.formService.formSignature;
      this.updateCanvas(this.signatureText);
    }
  }

  ngOnInit(): void {
  }


  updateCanvas($event: string) {
    this.context.clearRect(0,0,300, 50);
    this.context.canvas.width = 300;
    this.context.canvas.height = 50;
    this.context.restore();
    this.context.font="30px MeowScript";
    this.context.strokeText($event,10, 35);
    this.context.save();
  }

  saveSignature(): void {
    const sig: string = this.canvas.nativeElement.toDataURL("image/png");
    this.dialogRef.close({signatureText: this.signatureText, signatureImage: sig});
  }

  cancelDialog(): void {
    this.dialogRef.close({signatureText: null});
  }
}
