<mat-toolbar class="custom-header" [style.background-color]="Header.headerBackgroundColor" [style.height]="Header.headerHeight" >
  <a href="https://www.mcmaneslaw.com/" id="site-link" >
    <img class="law-logo" src="assets/img/headerImg/McManes_Logo.png" [style.height]="Header.logoHeight" [style.width]="Header.logoWidth" alt="test"/>
  </a>
  <!--<span class="header-title">TEST HEADER</span>-->
  <span class="example-spacer"></span>
  <div class="phone-span">
    <p class="free-consultation">Free Legal Consultation</p>
    <a class="phone-number-link" [href]="altPhoneNumber"><span [style.color]="Header.headerTextColor">{{Header.phoneNumber}}</span></a>
  </div>
</mat-toolbar>
