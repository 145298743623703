<dc-navbar-default [darkLinks]="true"></dc-navbar-default>
<div class="sign-up-header">
  <div [ngClass]="(signUpService.slideCount == 1)? 'active':''" class="sign-up-section">
    <div class="circle">
      <span>1</span>
    </div>
    <span class="section-text">Personal Information</span>
    <div [ngClass]="(signUpService.slideCount == 1)? 'active-triangle':''" class="sign-up-triangle"></div>
  </div>
  <div [ngClass]="(signUpService.slideCount == 2)? 'active':''" class="sign-up-section">
    <div class="circle">
      <span>2</span>
    </div>
    <span class="section-text">Form Selection</span>
    <div [ngClass]="(signUpService.slideCount == 2)? 'active-triangle':''" class="sign-up-triangle"></div>
  </div>
  <div [ngClass]="(signUpService.slideCount == 3)? 'active':''" class="sign-up-section">
    <div class="circle">
      <span>3</span>
    </div>
    <span class="section-text">Success</span>
  </div>
</div>
<div class='slide-container' [@slide]="{value:signUpService.slideName, params:{windowWidth:getWindowWidth(),slideNumber:signUpService.slideCount}}">
    <div #slide1 class="slide sign-up-slide">
      <div style="height: 100%; width:100%;position: relative;">
       <form [formGroup]="signUpService.signUpForm" (ngSubmit)="submitSignUpForm()" class='sign-up-form'>
         <p class="sign-up-title">Sign Up</p>
          <mat-grid-list cols="1" rowHeight="85px" gutterSize="5px" style="width:100%;height:100%;">
            <mat-grid-tile rowspan=1 colspan="1">
              <mat-form-field appearance="fill">
                <mat-label>Company Name</mat-label>
                <input formControlName="CompanyName" matInput required>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile rowspan=1 colspan="1">
              <mat-form-field appearance="fill">
                <mat-label>Full Name</mat-label>
                <input formControlName="FullName" matInput required>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile rowspan=1 colspan="1">
              <mat-form-field appearance="fill">
                <mat-label>Email</mat-label>
                <input formControlName="Email" matInput required>
              </mat-form-field>
              <mat-error style="margin-right: auto;top: 15px;position: relative;" *ngIf="signUpService.signUpForm.controls.Email?.errors?.email">Email must be in format ***@***.***</mat-error>
            </mat-grid-tile>
            <mat-grid-tile rowspan=1 colspan="1">
              <mat-form-field appearance="fill">
                <mat-label>Phone Number</mat-label>
                <input matInput formControlName="PhoneNumber" (keypress)="onKey($event)"
                (keydown.backspace)="onBackspaceKeydown($event)">
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
          <div class="terms-area">
            <mat-checkbox formControlName="AgreeTerms" class="terms-cb"></mat-checkbox>
            <div class="term-text-area">
              <p class="term-agreement">I agree to the LawFormsPro <a href="/privacy-policy">Privacy Policy</a> and <a href="/terms">Terms of Conditions</a>.</p>
            </div>
            <mat-error style="text-align:center;" *ngIf="signUpService.signUpForm.controls.AgreeTerms?.errors?.required">You must agree to Terms and Conditions</mat-error>
          </div>
          <div class="button-row">
            <button class="form-button" mat-raised-button>Continue</button>
          </div>
        </form>
      </div>
    </div>
    <div #slide2 class="slide card-slide">
      <pricing-total></pricing-total>
      <div class="card-slide-container">
        <div style="width:100%;">
          <div class="title-row">
            <p>Select Your Form(s)</p>
          </div>
          <div class="card-row">
            <mat-card [ngClass]="(selectedForms['Personal Injury']== true)?'selected-card':''" (click)="onCardClick('Personal Injury')">
              <span class="material-icons selected-icon" *ngIf="selectedForms['Personal Injury']">
                check_circle
                </span>
              <div class="card-content-holder">
                <core-icons name="personalinjury"></core-icons>
                <p class="card-title">Personal Injury</p>
              </div>
            </mat-card>
            <mat-card [ngClass]="(selectedForms['Worker\'s Compensation']== true)?'selected-card':''"  (click)="onCardClick('Worker\'s Compensation')">
              <span class="material-icons selected-icon" *ngIf="selectedForms['Worker\'s Compensation']">
                check_circle
                </span>
              <div class="card-content-holder">
                <core-icons name="workerscomp"></core-icons>
                <p class="card-title">Worker's Compensation</p>
              </div>
            </mat-card>
            <mat-card [ngClass]="(selectedForms['Social Security']== true)?'selected-card':''"  (click)="onCardClick('Social Security')">
              <span class="material-icons selected-icon" *ngIf="selectedForms['Social Security']">
                check_circle
                </span>
              <div class="card-content-holder">
                <core-icons name="socialsecurity"></core-icons>
                <p class="card-title">Social Security</p>
              </div>
            </mat-card>
            <mat-card [ngClass]="(selectedForms['Veteran Affairs']== true)?'selected-card':''"  (click)="onCardClick('Veteran Affairs')">
              <span class="material-icons selected-icon" *ngIf="selectedForms['Veteran Affairs']">
                check_circle
                </span>
              <div class="card-content-holder">
                <core-icons name="veteranAffairs"></core-icons>
                <p class="card-title">Veteran Affairs</p>
              </div>
            </mat-card>
            <mat-card [ngClass]="(selectedForms['Medicare']== true)?'selected-card':''"  (click)="onCardClick('Medicare')">
              <span class="material-icons selected-icon" *ngIf="selectedForms['Medicare']">
                check_circle
                </span>
              <div class="card-content-holder">
                <core-icons name="medicare"></core-icons>
                <p class="card-title">Medicare</p>
              </div>
            </mat-card>
          </div>
        </div>
        <div class="button-row">
          <button [ngClass]="isLoading?'loading-text':''" [disabled]="disabledCardButton || isLoading" class="form-button" mat-raised-button (click)="setupCompany()">
            <mat-spinner  class="loading-spinner" *ngIf="isLoading" diameter="30"></mat-spinner>
            Sign Up</button>
          <button (click)="signUpService.getPreviousSlide()" class="previous-button" mat-raised-button>Previous</button>
        </div>
      </div>
    </div>
    <!--<div #slide3 class="slide card-slide">
      <stripe-payment></stripe-payment>
    </div>
    <div #slide3 class="slide card-slide">
      <pricing-total></pricing-total>
      <mat-card class="discount-card">
        <mat-card-header>Activate Administrative Setup Fee Discount</mat-card-header>
        <core-icons name="discount"></core-icons>
        <p class='discount-text'>Save <span>20% off</span> the administrative setup fee if you agree to setup auto-pay.</p>
        <mat-checkbox class="discount-cb"
                  [checked]="discountSelected"
                  (change)="setDiscount($event.checked)" required>
          I Agree
        </mat-checkbox>

        <div class="button-row">
          <button class="form-button" [ngClass]="(isLoading== true)?'color-remover':''" mat-raised-button (click)="setupCompany()">
            <mat-spinner class="loading-spinner" *ngIf="isLoading" diameter="30"></mat-spinner>
            Continue
          </button>
          <button class="previous-button" mat-raised-button (click)="getPreviousSlide()">Previous</button>
        </div>
      </mat-card>
    </div>-->
    <div #slide4 class="slide card-slide">
      <p class="next-step-title">You have Successfully Signed Up!</p>
      <p class="next-step-subTitle">Here are the next steps:</p>
      <mat-card class="next-step-card">
        <div class="next-step-body">
          <div class="next-step-left-col">
            <div class="circle-bg">
              <span class="circle-num">1.</span>
            </div>
          </div>
          <div class="next-step-right-col">
            <p class="header-text">You will receive and email.</p>
            <p class="step-text">In the email you will receive 2 invoices.<br> The first invoice is for the administrative fee. <br>
              The second invoice is for the monthly payments.<br> Instructions on how to setup auto payment for the monthly payment will also be attached.</p>
          </div>
        </div>
      </mat-card>
      <mat-card class="next-step-card">
        <div class="next-step-body">
          <div class="next-step-left-col">
            <div class="circle-bg">
              <span class="circle-num">2.</span>
            </div>
          </div>
          <div class="next-step-right-col">
            <p class="header-text">Payment Recieved.</p>
            <p class="step-text">Once payment is received. We will begin working with Prevail to setup your account.</p>
          </div>
        </div>
      </mat-card>
      <mat-card class="next-step-card">
        <div class="next-step-body">
          <div class="next-step-left-col">
            <div class="circle-bg">
              <span class="circle-num">3.</span>
            </div>
          </div>
          <div class="next-step-right-col">
            <p class="header-text">Final Email.</p>
            <p class="step-text">This email will contain your personal link to your forms. All Done!</p>
          </div>
        </div>
      </mat-card>
      <div class="next-step-button-row">
        <button class="form-button" mat-raised-button>Back Home</button>
      </div>
    </div>
</div>
<dc-page-header-wave style="height:450px"
  [containerClass]="'pb-9'"
>
</dc-page-header-wave>
<!--<div style="position: absolute; bottom:200px; display:inline-flex">
  <button (click)="getPreviousSlide()"> Previous</button>
  <button (click)="getNextSlide()"> Next</button>
</div>-->
<dc-footer2></dc-footer2>
