import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import{NoCompanyComponent} from './no-company.component';
import { SharedModule } from '../../shared/shared.module';
import { FootersModule } from '../../blocks/footers/footers.module';
import { ActionsModule } from '../../blocks/actions/actions.module';
import { ShellModule } from '../../shell/shell.module';

@NgModule({
  declarations: [NoCompanyComponent],
  imports: [SharedModule, FootersModule, ActionsModule, ShellModule],
  exports:[NoCompanyComponent]
})
export class NoCompanyModule { }
