<dc-navbar-default [darkLinks]="false"></dc-navbar-default>
<dc-page-header-wave
  [title]="'Pricing Plan'"
  [subtitle]="
    'Simple and affordable pricing.'
  "
>
</dc-page-header-wave>

<dc-pricing-cards></dc-pricing-cards>
<!-- AVS: Removed pricing includes information, this will be listed in the pricing cards -->
<!-- <dc-pricing-include></dc-pricing-include> -->
<dc-faqs1
  [edge]="'bottom-right'"
  [background]="'light'"
  [classes]="'border-top'"
></dc-faqs1>
<!--<dc-start-now-box></dc-start-now-box>
<dc-cta1></dc-cta1>-->

<dc-footer2></dc-footer2>
