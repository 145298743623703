import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-athena-test-header',
  templateUrl: './athena-test-header.component.html',
  styleUrls: ['./athena-test-header.component.scss']
})
export class AthenaTestHeaderComponent implements OnInit {

  @Input()Header:any;
  public lawName: string;
  public altPhoneNumber:string='';
  static key ='AthenaTestHeaderComponent';
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.lawName = this.route.snapshot.params['lawName'];
    this.altPhoneNumber  = this.Header.phoneNumber.replace('(','');
    this.altPhoneNumber = this.altPhoneNumber.replace(')','');
    this.altPhoneNumber = this.altPhoneNumber.replace('-','');
    this.altPhoneNumber = 'tel:+1'+this.altPhoneNumber;
  }

}
