<section class="section b-b">
  <div class="container">
    <div class="row gap-y align-items-center text-center text-lg-left">
      <div class="col-12 col-md-6 py-4 px-5 b-md-r">
        <dc-feather [name]="'dollar-sign'" [iconClass]="'stroke-darker'"></dc-feather>
        <a href="javascript:;" class="mt-4 text-darker d-flex align-items-center">
          <h4 class="mr-3">Contact Sales</h4>
          <fa-icon [icon]="longArrowAltRight"></fa-icon>
        </a>
        <p class="mt-4">
          Looking for a custom quote? need to tell us more about your project? or want a demonstration? drop us a line
          to <a href="mailto:support@5studios.net">sales@5studios.net</a>
        </p>
      </div>

      <div class="col-12 col-md-6 py-4 px-5">
        <dc-feather [name]="'life-buoy'" [iconClass]="'stroke-darker'"></dc-feather>
        <a href="javascript:;" class="mt-4 text-darker d-flex align-items-center">
          <h4 class="mr-3">Technical Support</h4>
          <fa-icon [icon]="longArrowAltRight"></fa-icon>
        </a>
        <p class="mt-4">
          Any question about how to integrate your product?. Don't fret, our geek team is ready for you at
          <a href="mailto:support@5studios.net">support@5studios.net</a>
        </p>
      </div>
    </div>
  </div>
</section>
