<!-- ./Join - As Developer/Designer -->
<section class="section bg-light">
  <div class="container bring-to-front py-0">
    <div class="shadow bg-contrast p-5 rounded">
      <div class="row gap-y align-items-center text-center text-lg-left">
        <div class="col-12 col-md-6 py-4 px-5 b-md-r">
          <dc-feather [name]="'star'" [iconClass]="'stroke-primary'"></dc-feather>
          <a href="javascript:;" class="mt-4 text-primary d-flex align-items-center">
            <h4 class="mr-3">Join as Designer</h4>
          </a>
          <p class="mt-4">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores, consequuntur fugit minima natus optio
            quisquam sint sunt? Earum harum in laudantium nobis obcaecati odio, praesentium, quis sequi soluta vel
            veniam.
          </p>
        </div>

        <div class="col-12 col-md-6 py-4 px-5">
          <dc-feather [name]="'code'" [iconClass]="'stroke-info'"></dc-feather>
          <a href="javascript:;" class="mt-4 text-info d-flex align-items-center">
            <h4 class="mr-3">Join as Developer</h4>
          </a>
          <p class="mt-4">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores, consequuntur fugit minima natus optio
            quisquam sint sunt? Earum harum in laudantium nobis obcaecati odio, praesentium, quis sequi soluta vel
            veniam.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
