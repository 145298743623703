<form class="form form-contact" [formGroup]="contactUsForm" (ngSubmit)="submitContactForm()" data-response-message-animation="slide-in-up">
  <div class="form-group">
    <small id="emailHelp" class="form-text text-secondary mt-0 mb-2 italic"
        >We'll never share your email with anyone else.</small>
    <mat-form-field appearance="fill" class="form-input">
    <mat-label class="bold">Email address</mat-label>
      <input
        type="email"
        name="email"
        id="contact_email"
        class="form-input"
        placeholder="Valid Email"
        formControlName="Email"
        matInput
        required
      />
    </mat-form-field>
    <mat-error class="form-error" *ngIf="contactUsForm.controls.Email?.errors?.email &&(contactUsForm.controls.Email?.dirty || contactUsForm.controls.Email?.touched)">Valid Email format is required (***@***.***).</mat-error>
    <mat-error class="form-error" *ngIf="contactUsForm.controls.Email?.errors?.required &&(contactUsForm.controls.Email?.dirty || contactUsForm.controls.Email?.touched)">Valid Email is required.</mat-error>
  </div>

  <div class="form-group">
    <mat-form-field appearance="fill" class="form-input">
      <mat-label class="bold">Full Name</mat-label>
    <input
      type="text"
      name="name"
      id="contact_subject"
      placeholder="Full Name"
      formControlName="Name"
      matInput
      required
      >
    </mat-form-field>
    <mat-error class="form-error" *ngIf="contactUsForm.controls.Name?.errors?.required &&(contactUsForm.controls.Name?.dirty || contactUsForm.controls.Name?.touched)">Name is required.</mat-error>
  </div>

  <div class="form-group">
    <mat-form-field appearance="fill" class="form-input">
      <mat-label class="bold">Subject</mat-label>
    <input
      type="text"
      name="subject"
      id="contact_subject"
      placeholder="Subject"
      formControlName="Subject"
      matInput
      required
      >
    </mat-form-field>
    <mat-error class="form-error" *ngIf="contactUsForm.controls.Subject?.errors?.required &&(contactUsForm.controls.Subject?.dirty || contactUsForm.controls.Subject?.touched)">Subject is required.</mat-error>
  </div>

  <div class="form-group">
    <mat-form-field appearance="fill" class="form-input">
      <mat-label class="bold">Message</mat-label>
    <textarea
      name="message"
      id="contact_message"
      placeholder="What do you want to let us know?"
      formControlName="Message"
      rows="8"
      matInput
      required
    ></textarea>
    </mat-form-field>
    <mat-error class="form-error" *ngIf="contactUsForm.controls.Message?.errors?.required &&(contactUsForm.controls.Message?.dirty || contactUsForm.controls.Message?.touched)">Message is required.</mat-error>
  </div>

  <div class="form-group button-row">
    <button [ngClass]="(isLoading== true)?'color-remover':''"  [disabled]="!contactUsForm.valid" id="contact-submit" class="btn btn-primary btn-rounded form-button" mat-raised-button>
      Send Message
      <mat-spinner class="loading-spinner" *ngIf="isLoading" diameter="30"></mat-spinner>
    </button>
  </div>
</form>

<div class="response-message">
  <div class="section-heading">
    <i class="fas fa-check font-lg"></i>
    <p class="font-md m-0">Thank you!</p>
    <p class="response">Your message has been send, we will contact you as soon as possible.</p>
  </div>
</div>
