import { Component, HostBinding, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ContactUs } from 'src/app/pages/contact/interfaces/contact-us.interface';
import { FormService } from 'src/app/pages/forms/services/forms.service';
import { ProgressSnackBarComponent } from 'src/app/shared/progress-snack-bar/progress-snack-bar.component';
import{ContactUsService} from '../../../pages/contact/contact-us.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'dc-form-contact',
  templateUrl: './form-contact.component.html',
  styleUrls: ['./form-contact.component.scss']
})
export class FormContactComponent implements OnInit {
  @HostBinding("style.--snackbar-bg")
  public get snackbarBgAsStyle():any{
    return this.sanitizer.bypassSecurityTrustStyle(this.snackBarBg);
  }

  public contactUsForm:any;
  public isLoading:boolean = false;
  private snackBarBg:string = '';

  private contactUsObj:ContactUs ={
    email:'',
    subject:'',
    message:'',
    name:''
  }

  constructor(private fb: FormBuilder,  private sanitizer:DomSanitizer,public formService:FormService, private contactUsService: ContactUsService,private _snackBar: MatSnackBar, private router: Router) {}

  ngOnInit() {
    this.contactUsForm= this.fb.group({
      Email: new FormControl('',[Validators.required,Validators.email]),
      Name: new FormControl('', [Validators.required]),
      Subject:new FormControl('',[Validators.required]),
      Message: new FormControl('',[Validators.required])
    });
  }

  private showSuccessSnackBar(){
    this._snackBar.dismiss();
      this._snackBar.openFromComponent(ProgressSnackBarComponent,{
        duration:5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass:['snackbar-success']
      });
      this.formService.setValue('success');
      this.snackBarBg = '#4bb543';
  }

  private showErrorSnackBar(){
    this._snackBar.dismiss();
      this.formService.setValue('error');
      this._snackBar.openFromComponent(ProgressSnackBarComponent,{
        duration:5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass:['snackbar-error']
      });
      this.snackBarBg = '#ff9494';
  }

  public submitContactForm(){
    if(this.contactUsForm.valid){
      this.isLoading = true;

      this.contactUsObj={
        name:this.contactUsForm.value.Name,
        email:this.contactUsForm.value.Email,
        message:this.contactUsForm.value.Message,
        subject:this.contactUsForm.value.Subject
      };

      this.formService.setValue('loading');
      this._snackBar.openFromComponent(ProgressSnackBarComponent,{
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass:['snackbar-loading']
      });
      this.snackBarBg ='#363636';

      this.contactUsService.sendMessage(this.contactUsObj).subscribe(()=>{
        this.isLoading=false;
        this.showSuccessSnackBar();
        this.contactUsForm.reset();
        this.contactUsForm.controls.Email.setErrors(null);
        this.contactUsForm.controls.Name.setErrors(null);
        this.contactUsForm.controls.Message.setErrors(null);
        this.contactUsForm.controls.Subject.setErrors(null);
        this.contactUsObj={
          email:'',
          subject:'',
          message:'',
          name:''
        };

        this.router.navigate(['/contact-success']);
      },
      (error)=>{
        this.isLoading=false;
        this.showErrorSnackBar();
        console.log(error);
      });
    }
    else{
      console.log('it was not valid');
    }
  }
}
