import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-berkely-disability-header',
  templateUrl: './berkely-disability-header.component.html',
  styleUrls: ['./berkely-disability-header.component.scss']
})
export class BerkelyDisabilityHeaderComponent implements OnInit {

  @Input()Header:any;
  public lawName: string;
  public altPhoneNumber:string='';
  static key ='LawOfficeOfMLTHeaderComponent';

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.lawName = this.route.snapshot.params['lawName'];
    this.altPhoneNumber  = this.Header.phoneNumber.replace('(','');
    this.altPhoneNumber = this.altPhoneNumber.replace(')','');
    this.altPhoneNumber = this.altPhoneNumber.replace('-','');
    this.altPhoneNumber = 'tel:+1'+this.altPhoneNumber;
  }

}
