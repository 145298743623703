import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dc-features-icons-col',
  templateUrl: './features-icons-col.component.html',
  styleUrls: ['./features-icons-col.component.scss']
})
export class FeaturesIconsColComponent implements OnInit {
  elements = [
    { icon: 'image', title: 'Easily theme-able', subtitle: "Easily add your logo and colors to customize your intake site." },
    { icon: 'sliders', title: 'Professional tools', subtitle: "We work directly with Prevail Case Management System to ensure all your information is secure and uploaded in a timely manner." },
    {
      icon: 'target',
      title: 'Ready-to-use content',
      subtitle: "Choose from 4 ready-to-use templates that offer all the information you need from clients."
    }
  ];

  constructor() {}

  ngOnInit() {}
}
