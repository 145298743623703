import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignUpComponent } from './sign-up.component';
import { SharedModule } from 'src/app/shared';
import { ActionsModule } from 'src/app/blocks/actions/actions.module';
import { FootersModule } from 'src/app/blocks/footers/footers.module';
import { ShellModule } from 'src/app/shell/shell.module';
import { FormsModule as fModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import {MatFormFieldModule} from '@angular/material/form-field';
import {SignUpInputComponent} from './sign-up-input.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import { MatIconModule } from "@angular/material/icon";
import{IconModule} from './svg/svg-icons.module';
import { PricingTotalComponent } from './pricing-total/pricing-total.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { PricingComponent } from '../pricing/components/pricing/pricing.component';
import { StripePaymentModule } from '../stripe-payment/stripe-payment.module';
import { StripePaymentComponent } from '../stripe-payment/stripe-payment.component';

@NgModule({
  declarations: [SignUpComponent,SignUpInputComponent, PricingTotalComponent],
  imports: [
    CommonModule,
    SharedModule,
    FootersModule,
    ActionsModule,
    ShellModule,
    fModule,
    MatInputModule,
    MatGridListModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    IconModule,
    MatProgressSpinnerModule,
    StripePaymentModule
  ],
  exports:[SignUpComponent,SignUpInputComponent],
  entryComponents:[SignUpComponent,SignUpInputComponent],
})
export class SignUpModule { }
