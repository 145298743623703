<dc-navbar-default [darkLinks]="false"></dc-navbar-default>
<dc-page-header-wave
  [title]="'Success!'"
  [subtitle]="
    'Sign up was successful.'
  "
  [containerClass]="'pb-9'"
>
</dc-page-header-wave>
<div class="img-holder">
  <div class="next-step-title">
    <p>Next Steps</p>
  </div>
  <mat-card class="next-step-card">
    <div class="next-step-body">
      <div class="next-step-left-col">
        <div class="circle-bg">
          <span class="circle-num">1.</span>
        </div>
      </div>
      <div class="next-step-right-col">
        <p class="header-text">Folder setup with Prevail Case Management.</p>
        <p class="step-text"> We will be contacting Prevail Case Management to setup your form upload folder.</p>
      </div>
    </div>
  </mat-card>
  <mat-card class="next-step-card">
    <div class="next-step-body">
      <div class="next-step-left-col">
        <div class="circle-bg">
          <span class="circle-num">2.</span>
        </div>
      </div>
      <div class="next-step-right-col">
        <p class="header-text">Finalizing Account Details.</p>
        <p class="step-text"> Next, we will start creating your personal Header and other custom company artifacts for your form(s) site.</p>
      </div>
    </div>
  </mat-card>
  <mat-card class="next-step-card">
    <div class="next-step-body">
      <div class="next-step-left-col">
        <div class="circle-bg">
          <span class="circle-num">3.</span>
        </div>
      </div>
      <div class="next-step-right-col">
        <p class="header-text">Receive an Email with the Final Details.</p>
        <p class="step-text"> Lastly, you will recieve an email with the website link to your form(s). The complete process should not take more than a few hours.</p>
      </div>
    </div>
  </mat-card>
  <div class="next-step-button-row">
    <button class="form-button" mat-raised-button  (click)="goHome()">Back Home</button>
  </div>
</div>
<dc-footer1></dc-footer1>
