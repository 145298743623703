<mat-toolbar class="custom-header" [style.background-color]="Header.headerBackgroundColor" [style.height]="Header.headerHeight" >
  <div class="logo-box for-logo" *ngIf="!isMobile">
    <div class="top-left box-bar">
      <div class="top-bar"></div>
      <div class="bottom-bar"></div>
    </div>
    <div class="top-right box-bar">
      <div class="top-bar"></div>
      <div class="bottom-bar"></div>
    </div>
    <div class="bottom-left box-bar">
      <div class="top-bar"></div>
      <div class="bottom-bar"></div>
    </div>
    <div class="bottom-right box-bar">
      <div class="top-bar"></div>
      <div class="bottom-bar"></div>
    </div>
    <span class="logo-text">YOUR LOGO HERE</span>
  </div>
  <div class="logo-box for-mobile-logo" *ngIf="isMobile">
    <div class="top-left box-bar">
      <div class="top-bar"></div>
      <div class="bottom-bar"></div>
    </div>
    <div class="top-right box-bar">
      <div class="top-bar"></div>
      <div class="bottom-bar"></div>
    </div>
    <div class="bottom-left box-bar">
      <div class="top-bar"></div>
      <div class="bottom-bar"></div>
    </div>
    <div class="bottom-right box-bar">
      <div class="top-bar"></div>
      <div class="bottom-bar"></div>
    </div>
    <span class="logo-text">L</span>
  </div>
 <!--<span class="header-title">TEST HEADER</span>-->
  <span class="example-spacer"></span>
  <div class="phone-span">
    <div class="phone-icon-bg">
      <mat-icon class="phone-icon">phone_iphone</mat-icon>
    </div>
    <div class="logo-box for-phone" *ngIf="!isMobile">
      <div class="top-left box-bar">
        <div class="top-bar"></div>
        <div class="bottom-bar"></div>
      </div>
      <div class="top-right box-bar">
        <div class="top-bar"></div>
        <div class="bottom-bar"></div>
      </div>
      <div class="bottom-left box-bar">
        <div class="top-bar"></div>
        <div class="bottom-bar"></div>
      </div>
      <div class="bottom-right box-bar">
        <div class="top-bar"></div>
        <div class="bottom-bar"></div>
      </div>
      <span class="logo-text">YOUR PHONE NUMBER HERE</span>
    </div>
    <div class="logo-box for-mobile-phone" *ngIf="isMobile">
      <div class="top-left box-bar">
        <div class="top-bar"></div>
        <div class="bottom-bar"></div>
      </div>
      <div class="top-right box-bar">
        <div class="top-bar"></div>
        <div class="bottom-bar"></div>
      </div>
      <div class="bottom-left box-bar">
        <div class="top-bar"></div>
        <div class="bottom-bar"></div>
      </div>
      <div class="bottom-right box-bar">
        <div class="top-bar"></div>
        <div class="bottom-bar"></div>
      </div>
      <span class="logo-text">P</span>
    </div>
    <!--<a class="phone-number-link" [href]="altPhoneNumber"><span [style.color]="Header.headerTextColor">{{Header.phoneNumber}}</span></a>-->
  </div>
</mat-toolbar>

