import { NgModule } from '@angular/core';
import { FootersModule } from '../../blocks/footers/footers.module';
import { ShellModule } from '../../shell/shell.module';
import { Routes, RouterModule } from '@angular/router';
import { TermsComponent } from './components/terms/terms.component';
import { TermsTermsComponent } from './components/terms-terms/terms-terms.component';

@NgModule({
  declarations: [TermsComponent, TermsTermsComponent],
  imports: [FootersModule, ShellModule, RouterModule, ShellModule],
  exports: [TermsComponent],
})
export class TermsModule {}
