import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, HostBinding } from '@angular/core';
import { AfterViewInit, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import{customSvg} from './svg/custom-svg';
import{PricingTotalService} from './pricing-total/pricing-total.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import{SignUp} from './interfaces/sign-up.interface';
import{SignUpService} from './sign-up.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProgressSnackBarComponent } from 'src/app/shared/progress-snack-bar/progress-snack-bar.component';
import { FormService } from '../forms/services/forms.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  animations:[
    trigger('slide',[
      state('slide-1',style({'margin-left':'{{windowWidth}}px'}),
      {params:{windowWidth:0,slideNumber:1}}),
      state('slide-2',style({'margin-left':'{{windowWidth}}px'}),
      {params:{windowWidth:1440,slideNumber:2}}),
      state('slide-3',style({'margin-left':'{{windowWidth}}px'}),
      {params:{windowWidth:1440,slideNumber:3}}),
      state('slide-4',style({'margin-left':'{{windowWidth}}px'}),
      {params:{windowWidth:1440,slideNumber:4}}),
      transition('slide-1 <=> slide-2', animate('300ms ease-out')),
      transition('slide-2 <=> slide-3', animate('300ms ease-out')),
      transition('slide-3 <=> slide-4', animate('300ms ease-out'))
    ])
  ]
})
export class SignUpComponent implements AfterViewInit ,OnInit {

  @HostBinding("style.--snackbar-bg")
  public get snackbarBgAsStyle():any{
    return this.sanitizer.bypassSecurityTrustStyle(this.snackBarBg);
  }

  private snackBarBg:string = '';
  public windowWidth = 1440;
  public slideNumber=4;
  public slideCount=1;
  public slideName='slide-1';
  public fullName:string = 'Full Name';
  public loaded:boolean = false;
  public veteranAffairsIcon = customSvg.veteranAffairs;
  public selectedForms:boolean[]=[];
  public discountSelected:boolean = false;
  public signUpForm:any;
  public disabledCardButton:boolean = true;
  public isLoading:boolean= false;
  private formsList:string='';
  private signUpObj:SignUp = {
    companyName:'',
    fullName:'',
    phoneNumber:'',
    email:'',
    agreeToTerms:false,
    forms:'',
    //agreeToAutoPay:false,
    //stripeToken:''
  };
  private
  @ViewChild('slide1')
  slide1: ElementRef;

  @ViewChild('slide2')
  slide2: ElementRef;

  @ViewChild('slide3')
  slide3: ElementRef;

  @ViewChild('slide4')
  slide4: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.signUpService.slideName='slide-' + this.signUpService.slideCount;
  }

  ngAfterViewInit(){
    this.loaded= true;
    this.cd.detectChanges();
  }

  constructor(private cd: ChangeDetectorRef,
    private pricingService:PricingTotalService,
    private fb: FormBuilder,
    public formService:FormService,
    public signUpService:SignUpService,
    private router: Router,
    private sanitizer:DomSanitizer,
    private _snackBar: MatSnackBar) { }
  ngOnInit(): void {

    this.signUpService.signUpForm = this.fb.group({
      CompanyName: new FormControl('',[Validators.required]),
      FullName:new FormControl('',[Validators.required]),
      PhoneNumber:new FormControl('',[]),
      Email:new FormControl('',[Validators.required,Validators.email]),
      AgreeTerms: new FormControl(false,[Validators.requiredTrue])
    });

    this.pricingService.getSelectedForms().subscribe((forms)=>{
      this.signUpService.formsList='';
      this.signUpService.formCount = 0;
      Object.keys(forms).forEach(f=>{
        if(forms[f]){
          this.signUpService.formCount++;
          this.signUpService.formsList+=f+',';
        }
      });

      if(this.signUpService.formCount <= 2){
        this.signUpService.monthlyPrice = 199;
      }
      else if(this.signUpService.formCount == 3){
        this.signUpService.monthlyPrice = 199+25;
      }
      else if(this.signUpService.formCount == 4){
        this.signUpService.monthlyPrice = 199+50;
      }

      this.signUpService.signUpObj.forms = this.signUpService.formsList;

    });

    this.pricingService.getDiscountApplied().subscribe((d)=>{
     // this.signUpObj.agreeToAutoPay = d;
    });
  }


  public getWindowWidth(){

    if(this.loaded){
       const w =this['slide'+this.signUpService.slideCount].nativeElement.offsetWidth;
      const sz = -1*(w*(this.signUpService.slideCount-1));
      return sz;
    }
    return 0;
  }

  public submitSignUpForm(){
    if(this.signUpService.signUpForm.valid){

      this.signUpService.signUpObj.companyName = this.signUpService.signUpForm.value.CompanyName;
      this.signUpService.signUpObj.fullName = this.signUpService.signUpForm.value.FullName;
      this.signUpService.signUpObj.email = this.signUpService.signUpForm.value.Email;
      this.signUpService.signUpObj.phoneNumber = this.signUpService.signUpForm.value.PhoneNumber;
      this.signUpService.signUpObj.agreeToTerms = this.signUpService.signUpForm.value.AgreeTerms;

      if(this.signUpService.slideCount < this.signUpService.slideNumber){
        this.signUpService.slideCount++;
        this.signUpService.slideName='slide-'+this.signUpService.slideCount;
      }
    }
    else{

    }
  }

  public setupCompany(){
    this.isLoading=true;
    this.signUpService.signUpNewCompany(this.signUpService.signUpObj).subscribe((data)=>{
      //this.signUpService.getNextSlide();
      this.router.navigate(['/signup-success']);
      this.isLoading=false;
    },
    (error)=>{
      console.log('Error');
      this.isLoading=false;
      this.showErrorSnackBar();
    });
  }


  private showErrorSnackBar(){
    this._snackBar.dismiss();
      this.formService.setValue('error-payment');
      this._snackBar.openFromComponent(ProgressSnackBarComponent,{
        duration:10000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass:['snackbar-error']
      });
      this.snackBarBg = '#ff9494';
  }

  public onCardClick(formSelected:string){
    this.selectedForms[formSelected]=!this.selectedForms[formSelected];
    let v = false;
    Object.keys(this.selectedForms).forEach(f=>{
      if(this.selectedForms[f]){
        v = true;

      }
    });

    if(v){
      this.disabledCardButton = false;
    }
    else{
      this.disabledCardButton = true;
    }

    this.pricingService.setSelectedForms(formSelected);
  }

  setDiscount(completed: boolean) {
    this.discountSelected = completed;
    this.pricingService.setDiscountApplied(completed);
  }

  public onKey($event) {
    var length = $event.target.value.length;

    if (isNaN(Number($event.key)) || length == 13) {
      $event.preventDefault();
      return;
    }

    if (length == 0) {
      $event.target.value = '(' + $event.key;
      $event.preventDefault();
    } else if (length == 3) {
      $event.target.value += $event.key + ')';
      $event.preventDefault();
    } else if (length == 4) {
      $event.target.value += ')' + $event.key;
      $event.preventDefault();
    } else if (length == 7) {
      $event.target.value += $event.key + '-';
      $event.preventDefault();
    } else if (length == 8) {
      $event.target.value += '-' + $event.key;
      $event.preventDefault();
    }
  }
  public onBackspaceKeydown($event) {
    var length = $event.target.value.length;

    if (length == 10) {
      $event.target.value = $event.target.value.substring(0, 9);
    } else if (length == 6) {
      $event.target.value = $event.target.value.substring(0, 5);
    } else if (length == 2) {
      $event.target.value = '';
    }
  }
}
