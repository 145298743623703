<div class="form-loader">
  <div class="section">
    <div class="section-header ">
      <div class="section-circle animated-background"></div>
      <div class="section-title animated-background-2"></div>
    </div>
    <div class="section-body">
      <mat-grid-list class="section-tile-list" cols="4" rowHeight="40px" gutterSize="20px">
        <mat-grid-tile colspan="1" rowspan="1">
          <div class="section-ctrl animated-background"></div>
        </mat-grid-tile>
        <mat-grid-tile colspan="1" rowspan="1">
          <div class="section-ctrl animated-background-2"></div>
        </mat-grid-tile>
        <mat-grid-tile colspan="1" rowspan="1">
          <div class="section-ctrl animated-background"></div>
        </mat-grid-tile>
        <mat-grid-tile colspan="1" rowspan="1">
          <div class="section-ctrl animated-background"></div>
        </mat-grid-tile>
        <mat-grid-tile colspan="2" rowspan="2">
          <div class="section-ctrl animated-background"></div>
        </mat-grid-tile>
        <mat-grid-tile colspan="2" rowspan="2">
          <div class="section-ctrl animated-background-2"></div>
        </mat-grid-tile>
        <mat-grid-tile colspan="1" rowspan="1">
          <div class="section-ctrl animated-background"></div>
        </mat-grid-tile>
        <mat-grid-tile colspan="2" rowspan="1">
          <div class="section-ctrl animated-background"></div>
        </mat-grid-tile>
        <mat-grid-tile colspan="1" rowspan="1">
          <div class="section-ctrl animated-background"></div>
        </mat-grid-tile>
        <mat-grid-tile colspan="2" rowspan="1">
          <div class="section-ctrl animated-background"></div>
        </mat-grid-tile>
        <mat-grid-tile colspan="2" rowspan="1">
          <div class="section-ctrl animated-background-2"></div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>
  <div class="section">
    <div class="section-header ">
      <div class="section-circle animated-background"></div>
      <div class="section-title animated-background-2"></div>
    </div>
    <div class="section-body">
      <mat-grid-list class="section-tile-list" cols="4" rowHeight="40px" gutterSize="20px">
        <mat-grid-tile colspan="2" rowspan="1">
          <div class="section-ctrl animated-background"></div>
        </mat-grid-tile>
        <mat-grid-tile colspan="2" rowspan="1">
          <div class="section-ctrl animated-background-2"></div>
        </mat-grid-tile>
        <mat-grid-tile colspan="1" rowspan="1">
          <div class="section-ctrl animated-background"></div>
        </mat-grid-tile>
        <mat-grid-tile colspan="1" rowspan="1">
          <div class="section-ctrl animated-background-2"></div>
        </mat-grid-tile>
        <mat-grid-tile colspan="1" rowspan="1">
          <div class="section-ctrl animated-background"></div>
        </mat-grid-tile>
        <mat-grid-tile colspan="1" rowspan="1">
          <div class="section-ctrl animated-background"></div>
        </mat-grid-tile>
        <mat-grid-tile colspan="1" rowspan="1">
          <div class="section-ctrl animated-background"></div>
        </mat-grid-tile>
        <mat-grid-tile colspan="1" rowspan="1">
          <div class="section-ctrl animated-background-2"></div>
        </mat-grid-tile>
        <mat-grid-tile colspan="1" rowspan="1">
          <div class="section-ctrl animated-background"></div>
        </mat-grid-tile>
        <mat-grid-tile colspan="1" rowspan="1">
          <div class="section-ctrl animated-background"></div>
        </mat-grid-tile>
        <mat-grid-tile colspan="2" rowspan="2">
          <div class="section-ctrl animated-background"></div>
        </mat-grid-tile>
        <mat-grid-tile colspan="2" rowspan="2">
          <div class="section-ctrl animated-background-2"></div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>
  <div class="section">
    <div class="section-header ">
      <div class="section-circle animated-background"></div>
      <div class="section-title animated-background-2"></div>
    </div>
    <div class="section-body">
      <mat-grid-list class="section-tile-list" cols="4" rowHeight="40px" gutterSize="20px">
        <mat-grid-tile colspan="2" rowspan="1">
          <div class="section-ctrl animated-background"></div>
        </mat-grid-tile>
        <mat-grid-tile colspan="2" rowspan="1">
          <div class="section-ctrl animated-background-2"></div>
        </mat-grid-tile>
        <mat-grid-tile colspan="2" rowspan="2">
          <div class="section-ctrl animated-background"></div>
        </mat-grid-tile>
        <mat-grid-tile colspan="2" rowspan="2">
          <div class="section-ctrl animated-background-2"></div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>
</div>
