
export class SignedFormFile{
  name: string = '';
  pageAmount: number = 1;
  signature: string  = '';
  signatureImage: string = '';
  documentSentTimeStamp: Date = new Date();
  documentOpenedTimeStamp: Date = new Date();
  documentSignedTimeStamp: Date = new Date();
};
