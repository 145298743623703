import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-demo-header',
  templateUrl: './demo-header.component.html',
  styleUrls: ['./demo-header.component.scss']
})
export class DemoHeaderComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (event) {
      if (event.target.innerWidth > 800) {
        this.isMobile = false;
      }
      else{
        this.isMobile = true;
      }
    } else {
      if (window.innerWidth > 800) {
        this.isMobile = false;
      }
      else{
        this.isMobile= true;
      }
    }
  }


  @Input()Header:any;
  public lawName: string;
  public altPhoneNumber:string='';
  static key ='TestHeaderComponent';
  public isMobile:boolean = false;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.onResize();
    this.lawName = this.route.snapshot.params['lawName'];
    this.altPhoneNumber  = this.Header.phoneNumber.replace('(','');
    this.altPhoneNumber = this.altPhoneNumber.replace(')','');
    this.altPhoneNumber = this.altPhoneNumber.replace('-','');
    this.altPhoneNumber = 'tel:+1'+this.altPhoneNumber;
  }

}
